import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Alert, Back, Button, Radio, Switch } from 'src/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { useTranslation } from 'react-i18next'

const AddStore = (props) => {

    const { t } = useTranslation();
    const { onBack } = props;
    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })

    const [newAccount, setNewAccount] = useState(false)
    const [listAccount, setListAccount] = useState([])
    const [branch, setBranch] = useState({
        "street_address": "",
        "city_province": "",
        "district": "",
        "postal_code": "",
        "latitude": "",
        "longitude": "",
        "opening_time": "",
        "closure_time": "",
        "is_active": true,
        "restaurants": ""
    })

    const [account, setAccount] = useState({
        "id": -1,
        "mobile": "",
        "email": "",
        "first_name": "",
        "last_name": "",
        "password": "",
        "is_active": false,
        "is_superuser": false
    })

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.USER_ACCOUNTS)
            .then(res => {
                setListAccount(res.data[0].data)
            })
            .catch(error => { });
    }, [])

    const [store, setStore] = useState({ restaurant_image: '', name: '', is_active: 'false', type: '1', hotline: '' })
    const [urlImage, setUrlImage] = useState();

    const onChangeFile = event => {
        setUrlImage(URL.createObjectURL(event.target.files[0]))
        setStore({ ...store, restaurant_image: event.target.files[0] })
    }
    const onChangeStore = event => {
        const { name, value } = event.target;
        setStore({ ...store, [name]: value })
    }

    const onchangeBranch = (event) => {
        const { name, value } = event.target
        setBranch({ ...branch, [name]: value })
    }

    //-----------------------select from available user-------------------------------
    const changeAccountSelect = (event) => {
        const acc = listAccount[event.target.value]
        setAccount(acc);
    }
    //----------------------------------------------------------------------------------    

    const switchCallBack = (status) => {
        setNewAccount(status)
    }

    const btnAddClick = () => {
        //submit with new account
        if (newAccount) {
            const newAccountInfo = {
                "mobile": account.mobile,
                "email": account.email,
                "first_name": account.first_name,
                "last_name": account.last_name,
                "facebook_id": "",
                "google_id": "",
                "password": account.password
            }
            //create user
            axios.post(apis.BASE_URL + apis.USER_ACCOUNT_NEW, newAccountInfo)
                .then(res => {
                    //create profile
                    const idUser = res.data[0].data.id;
                    const profile = {
                        photo: null,
                        account: idUser
                    }
                    axios.post(apis.BASE_URL + apis.USER_PROFILE_NEW, profile)
                        .then(() => {
                            //create restaurant
                            const form = new FormData()
                            form.append("name", store.name)
                            form.append("is_active", store.is_active)
                            form.append("restaurant_image", store.restaurant_image)
                            form.append("hotline", store.hotline)
                            form.append("commission", 0)
                            form.append("type", 2)

                            axios.post(apis.BASE_URL + apis.RESTAURANT_ADD, form)
                                .then(res => {
                                    const idRestaurant = res.data[0].data.id;
                                    const branchInfo = { ...branch, restaurants: idRestaurant }
                                    axios.post(apis.BASE_URL + apis.BRANCH_ADD, branchInfo)
                                        .then(res => {
                                            //create assign
                                            const payload = {
                                                "user": idUser,
                                                "restaurant": idRestaurant,
                                                "type": 1
                                            }
                                            axios.post(apis.BASE_URL + apis.ASSIGN_USER_FOR_RESTAURANT, payload)
                                                .then(res => {
                                                    setAlert({ ...alert, type: 'success', open: true, msg: 'Successfully' })
                                                    onBack()
                                                })
                                                .catch(error => {
                                                    setAlert({ ...alert, type: 'error', open: true, msg: 'Update failed, please try again.' })
                                                });
                                        })
                                        .catch(error => {
                                            setAlert({ ...alert, type: 'error', open: true, msg: 'Update failed, please try again.' })
                                        });

                                })
                                .catch(error => {
                                    setAlert({ ...alert, type: 'error', open: true, msg: 'Update failed, please try again.' })
                                });
                        })
                        .catch(() => {
                            setAlert({ type: 'error', open: true, msg: 'Phone number of restaurants already exists' });
                        })
                })
                .catch(error => {
                    setAlert({ ...alert, type: 'error', open: true, msg: 'Phone number or email of user already exists' })
                })
        }
        //submit with available account
        else {
            const form = new FormData()
            form.append("name", store.name)
            form.append("is_active", store.is_active)
            form.append("restaurant_image", store.restaurant_image)
            form.append("hotline", store.hotline)
            form.append("commission", 0)
            form.append("type", 2)


            axios.post(apis.BASE_URL + apis.RESTAURANT_ADD, form)
                .then(res => {
                    const idRestaurant = res.data[0].data.id;
                    console.log(res)
                    //create new branch
                    const branchInfo = { ...branch, "restaurants": idRestaurant }
                    axios.post(apis.BASE_URL + apis.BRANCH_ADD, branchInfo)
                        .then(res => {
                            //create assign
                            const payload = {
                                "user": account.id,
                                "restaurant": idRestaurant,
                                "type": 1
                            }
                            axios.post(apis.BASE_URL + apis.ASSIGN_USER_FOR_RESTAURANT, payload)
                                .then(res => {
                                    setAlert({ ...alert, type: 'success', open: true, msg: 'Successfully' })
                                    onBack()
                                })
                                .catch(error => { setAlert({ ...alert, type: 'error', open: true, msg: 'There are some error while submiting' }) });
                        })
                        .catch(error => { setAlert({ ...alert, type: 'error', open: true, msg: 'There are some error while submiting' }) });

                })
                .catch(error => { setAlert({ ...alert, type: 'error', open: true, msg: 'Phone number of restaurants already exists' }) });
        }
    }


    //---------------------new user----------------------------------------
    const [confirmPassword, setConfirmPassword] = useState('')

    const [checkPasswordMatch, setCheckPasswordMatch] = useState(false)

    const changeAccount = (event) => {
        setAccount({ ...account, [event.target.name]: event.target.value })
    }

    const confirm = (event) => {
        if (event.target.value === account.password && account.password.length >= 8) {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(true)
        }
        else {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(false)
        }
    }

    //check validate password to render announce
    const renderTextCheck = () => {
        if (checkPasswordMatch) {
            return <span style={{ color: 'green' }}>Correct</span>
        }
        else {
            return <span style={{ color: 'red' }}>Not correct</span>
        }
    }

    //--------------------------------------------------------------------------------------
    //render user when change status of switch
    const renderAccount = () => {
        if (newAccount) {
            return (
                <>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('firstName')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            name='first_name'
                            value={account.first_name || ''}
                            onChange={changeAccount}
                            required
                        />
                    </div>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('lastName')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            name='last_name'
                            value={account.last_name || ''}
                            onChange={changeAccount}
                            required
                        />
                    </div>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('phoneNumber')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            name='mobile'
                            value={account.mobile || ''}
                            onChange={changeAccount}
                            required
                        />
                    </div>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('email')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            name='email'
                            value={account.email || ''}
                            onChange={changeAccount}
                            required
                            type="email"
                        />
                    </div>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('password')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            type="password"
                            name='password'
                            value={account.password || ''}
                            onChange={changeAccount}
                            required
                        />
                    </div>
                    <span style={{ color: '#3a414185', fontSize: '12px', marginBottom: '0.8rem' }}>Password must have at least 8 characters.</span>
                    <div className='edit_form-field'>
                        <p className='edit_form-key'>
                            <span className='edit_form-name'>{t('confirmPassword')}</span>
                            <span className='edit_form-name'>:</span>
                        </p>
                        <input
                            type="password"
                            name='confirmPassword'
                            value={confirmPassword || ''}
                            onChange={confirm}
                            required
                            style={{ marginRight: '1%' }}
                        />
                        {renderTextCheck()}
                    </div>
                </>
            )
        }
        else {
            return (
                <div className="form">
                    <span style={{ fontWeight: '700', color: 'black', fontSize: '1.3rem' }}>{t('newRestaurant.chooseAccount')}: </span>
                    <select className="enable" name="payment_method" onChange={changeAccountSelect} style={{ padding: '1%', borderRadius: '15px', color: '#e82d86', outline: 'none', border: 'none', backgroundColor: '#d2d2d259' }}>
                        <option value={-1}>----</option>
                        {
                            listAccount.map((acc, index) => {
                                return <option value={index} key={index} style={{ color: '#e82d86', fontWeight: '400' }}>{acc.first_name + acc.last_name}</option>
                            })

                        }
                    </select>
                </div>
            )
        }
    }

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-key'>
                    <span className='edit_form-name' style={{ color: ' #e82d86' }}>New account</span>
                    <span className='edit_form-name'><Switch callBack={switchCallBack} /></span>
                </div>
                <br /><hr /><br /><br /><br />
                {
                    renderAccount()
                }

                <div className='edit_form-field'>
                    <div className="mad_service" style={{ width: '100%', height: 'auto' }}>
                        <div className='image'>
                            <p>{t('image')}</p>
                            <div style={{ display: 'flex' }}>
                                <label htmlFor='commission_image'>
                                    <span style={{ display: !urlImage ? 'block' : 'none' }}>{t('browse')}</span>
                                    <img alt='order_image' src={urlImage} width='100%' height='auto' style={{ display: urlImage ? 'inline' : 'none' }} />
                                </label>
                                <input
                                    onChange={onChangeFile}
                                    id='commission_image'
                                    type='file'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>Name</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='name'
                        value={store.name || ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.hotline')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='hotline'
                        value={store.hotline || ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('status')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <div className='edit_form-radioGroup'>
                        <Radio
                            label={t('active')}
                            color='#e82d86'
                            checked={store.is_active === 'true'}
                            onSelect={() => setStore({ ...store, is_active: 'true' })}
                        />
                        <Radio
                            label={t('inactive')}
                            color='#e82d86'
                            checked={store.is_active === 'false'}
                            onSelect={() => setStore({ ...store, is_active: 'false' })}
                        />
                    </div>
                </div>
                <hr /><br /><br />
                <p><span className='edit_form-name' style={{ color: '#e82d86' }}><FontAwesomeIcon icon={faStore} />Branch of store</span></p>
                <br /><br />
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.streetAddress')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='street_address'
                        value={branch.street_address || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.cityProvince')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='city_province'
                        value={branch.city_province || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.district')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='district'
                        value={branch.district || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.postalCode')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='postal_code'
                        value={branch.postal_code || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.openingTime')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='opening_time'
                        value={branch.opening_time || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.branchOfRestaurant.closingTime')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='closure_time'
                        value={branch.closure_time || ''}
                        onChange={onchangeBranch}
                        required
                    />
                </div>
                <div className='edit_form-button'>
                    <Button
                        title={t('create')}
                        onClick={btnAddClick}
                    />
                </div>
            </div>
        </>
    )
}

AddStore.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func
}
AddStore.defaultProps = {
    onBack: () => { },
    onAdd: () => { }
}

export default AddStore;
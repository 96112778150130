import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, Back, Select, Input, Button, Pagination, ProgressBar } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import 'src/views/StoreConfig/component/TabPanes/SaleOrder/components/ListOrder/ListOrder.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faEye } from '@fortawesome/free-solid-svg-icons'
import ModalLocation from './ModalLocation'
import ModalDrivers from './ModalDrivers'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'

const TableOrders = props => {

    const { t } = useTranslation();
    const { onBack, onLoadDetail } = props;

    const [orders, setOrders] = useState([])
    const [drivers, setDrivers] = useState([])
    const [loading, setLoading] = useState(true)
    const [filterOption, setFilterOption] = useState('ID');
    const [filterVal, setFilterVal] = useState('');
    const [alert, setAlert] = useState({ type: 'success', open: false, msg: '' })

    // for pagination
    const [page, setPage] = useState(1);

    //for modal view location in map
    const [driverViewLocation, setDriverViewLocation] = useState({})
    const [showLocation, setShowLocation] = useState(false)

    //for match order to driver
    const [orderMatch, setOrderMatch] = useState({})
    const [showMatch, setShowMatch] = useState(false)

    const renderAction = (order) => {
        var leng = order.trackings.length
        if (order.trackings[leng - 1].status === -1) return <td>--</td>
        else if (order.trackings[leng - 1].status === -99) return <th>Canceled</th>
        else if (leng === 0 || order.trackings[leng - 1].driver === null) {
            return <td><Button title="Match" onClick={() => { setOrderMatch(order); setShowMatch(true) }} /></td>
        }
        else return <td><FontAwesomeIcon icon={faMapMarker} color="#e82d86" onClick={() => { setDriverViewLocation(order.trackings[leng - 1].driver); setShowLocation(true) }} /></td>
    }
    const renderPayment = (order) => {
        if (order.payments.length > 0 && order.payments.payment_method !== null) {
            if (order.payments.payment_method === 1) return <td>CARD</td>
            return <td>COD</td>
        }
        return <td>---</td>
    }
    const onSuccess = () => {
        setAlert({ ...alert, type: 'success', open: true, msg: 'Match driver successfully!' })
        setShowMatch(false)
        axios.get(apis.BASE_URL + apis.SALE_ORDER_ALL + `?page=${page}`)
            .then(res => {
                setOrders(res.data[0].data.results)
            })
    }

    const fetchOrders = () => {
        setLoading(true);
        axios.get(apis.BASE_URL + apis.SALE_ORDER_ALL + `?page=${page}`)
            .then(res => setOrders(res.data[0].data.results))
            .catch(() => setOrders([]))
            .finally(() => setLoading(false))
    }

    // filter orders
    const filterOrdersByID = id => {
        setLoading(true);
        axios.get(apis.BASE_URL + apis.SALE_ORDERS_BY_ID + id)
            .then(res => {
                setPage(null);
                setOrders(res.data[0].data)
            })
            .catch(() => setAlert({ type: 'error', open: true, msg: 'Network failed, please try again later.' }))
            .finally(() => setLoading(false))
    }
    const filterOrdersByDate = date => {
        setLoading(true);
        axios.get(apis.BASE_URL + apis.SALE_ORDERS_BY_DATE + date)
            .then(res => {
                setPage(null);
                setOrders(res.data[0].data)
            })
            .catch(() => setAlert({ type: 'error', open: true, msg: 'Network failed, please try again later.' }))
            .finally(() => setLoading(false))
    }
    const onFilterOrders = () => {
        filterVal ?
            (filterOption === 'ID' ?
                filterOrdersByID(filterVal) :
                filterOrdersByDate(filterVal)) :
            setPage(1)
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.DRIVER_ACCOUNTS)
            .then(res => {
                setDrivers(res.data)
            })
    }, [])

    useEffect(() => {
        page && fetchOrders()
    }, [page])

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <Back onBack={onBack} />
            <ModalDrivers order={orderMatch} show={showMatch} onClose={() => { setShowMatch(false) }} onSuccess={onSuccess} />
            <ModalLocation driver={driverViewLocation} show={showLocation} onClose={() => { setShowLocation(false) }} />
            <div className='filter-order'>
                <Select
                    className='filter-select'
                    options={['ID', 'Date']}
                    value={filterOption}
                    onChange={op => {
                        setFilterVal('');
                        setFilterOption(op)
                    }}
                />
                <NumberFormat
                    customInput={Input}
                    allowNegative={false}
                    format={filterOption === 'ID' ? '####' : '####-##-##'}
                    mask='_'
                    fieldClassName='filter-text'
                    placeholder={filterOption === 'ID' ? '####' : 'YYYY-MM-DD'}
                    value={filterVal}
                    onChange={e => setFilterVal(e.target.value)}
                />
                <Button title={filterVal ? 'Filter' : 'Reload'} onClick={onFilterOrders} />
            </div>
            <div className="list-order">
                <table>
                    <thead>
                        <tr>
                            <th>{t('orderTable.order')}</th>
                            <th>{t('orderTable.customerName')}</th>
                            <th>{t('orderTable.customerPhone')}</th>
                            <th>{t('orderTable.driverName')}</th>
                            <th>{t('orderTable.driverPhone')}</th>
                            <th>{t('date')}</th>
                            <th>{t('time')}</th>
                            <th>{t('orderTable.total')} $</th>
                            <th>{t('orderTable.paymentMethod')}</th>
                            <th>{t('orderTable.commission')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading === true &&
                            <tr>
                                <td colSpan={11} style={{ textAlign: 'center' }}>
                                    <ProgressBar />
                                </td>
                            </tr>
                        }
                        {
                            !orders || (orders && orders.length === 0) ?
                                <tr>
                                    <td colSpan={11} style={{ textAlign: 'center' }}>
                                        <div style={{ color: '#e82d86', marginTop: '15%', marginLeft: '30%' }}>No data.</div>
                                    </td>
                                </tr>
                                : null
                        }
                        {
                            orders && orders.map((order, index) => {
                                const { trackings, account } = order;
                                const tracking = (trackings && trackings.length > 0) ? trackings[trackings.length - 1] : null;
                                const driver = (tracking && tracking.driver) ? drivers.find(d => d.id === tracking.driver.id) : null;

                                return (
                                    <tr key={index}>
                                        <th>#{order.id}</th>
                                        <th>{account.first_name + " " + account.last_name}</th>
                                        <td>{account.mobile}</td>
                                        <th>{(driver && `${driver.user.first_name} ${driver.user.last_name}`) || '---'}</th>
                                        <td>{(driver && driver.user.mobile) || '---'}</td>
                                        <td>{moment(order.created_datetime).format('MM/DD/YYYY')}</td>
                                        <td>{moment(order.created_datetime).format('LT')}m</td>
                                        <th>$ {order.final_total}</th>
                                        {renderPayment(order)}
                                        <td>{(driver && driver.commission) || '---'}</td>
                                        <td className="view-detail" onClick={() => onLoadDetail(order, driver)}><FontAwesomeIcon icon={faEye} /></td>
                                        {renderAction(order)}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
            <Pagination
                currentPage={page}
                nextClick={() => setPage(page + 1)}
                preClick={() => setPage(page - 1)}
            />
        </>
    )
}

TableOrders.propTypes = {
    onBack: PropTypes.func
}

export default TableOrders;
import React, { useState, useEffect } from 'react';
import './MenuItem.scss'
import { Badge, Button } from 'src/components';
import { useTranslation } from 'react-i18next'
import { ProgressBar } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'

const MenuItem = (props) => {

    const { t } = useTranslation();
    const restaurant = localStorage.getItem('restaurant');

    const [items, setItems] = useState();

    useEffect(() => {
        if (restaurant) {
            axios.get(apis.BASE_URL + apis.MENUS + restaurant)
                .then(res => {
                    if (res.data[1] === 201 && res.data[0].status_code === 201) {
                        setItems(res.data[0].data)
                    }
                })
        }
    }, [restaurant])

    const viewDetailClick = (item) => {
        props.viewDetailCallBack(item)
    }

    const btnAddClick = () => {
        props.callBack('new-item')
    }

    return (
        <div className="menu-item">
            <Button title={t('create')} onClick={btnAddClick} />
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t('image')}</th>
                        <th>{t('name')}</th>
                        <th>{t('price')}</th>
                        <th>{t('status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!items && (
                        <tr>
                            <td colSpan={6}>
                                <ProgressBar size={24} />
                            </td>
                        </tr>
                    )}
                    {items && items.length > 0 ?
                        items.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td><img alt='' src={apis.BASE_URL + item.item_image} /></td>
                                <th>{item.item_name}</th>
                                <td>${item.unit_price}</td>
                                <td className="label">
                                    <Badge type={item.availability ? 'action' : 'block'} content={item.availability ? t('available') : t('unavailable')} />
                                </td>
                                <td className="view-detail" onClick={() => viewDetailClick(item)}>{t('view')}</td>
                            </tr>
                        )) : (items && items.length === 0 ? (
                            <tr>
                                <td colSpan={6}>No data found</td>
                            </tr>
                        ) : null)
                    }
                </tbody>
            </table>
        </div>
    );
}

export default MenuItem;
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getToken } from 'src/utils/localStorageService'
import JwtDecode from 'jwt-decode'

const Auth = props => {

    const history = useHistory();
    const { pathname } = history.location;

    React.useEffect(() => {
        const token = getToken();
        const expired = (token && token !== 'undefined') ? JwtDecode(token).exp : '';
        if ((!token || expired <= (Date.now() / 1000))) {
            history.push('/login')
        } else if (token && expired > (Date.now() / 1000)) {
            if (pathname === '/' || pathname === '/login') {
                history.push('/store-config')
            }
        }
    }, [history, pathname])
    return null
}

export default Auth;
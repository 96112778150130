import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Pagination, ProgressBar } from 'src/components'
import moment from 'moment'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { useTranslation } from 'react-i18next'

const DriverOrders = props => {

    const { driver, onBack } = props;
    const { t } = useTranslation();

    const [driverOrders, setDriverOrders] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.SALE_ORDERS_BY_DRIVER + driver.id + `&page=${page}`)
            .then(res => setDriverOrders(res.data[0].data.results))
    }, [driver, page])

    return (
        <>
            <Back onBack={onBack} />
            <div className="list-order">
                <table>
                    <thead>
                        <tr>
                            <th>{t('orderTable.order')}</th>
                            <th>{t('orderTable.customerName')}</th>
                            <th>{t('orderTable.customerPhone')}</th>
                            <th>{t('date')}</th>
                            <th>{t('time')}</th>
                            <th>{t('orderTable.total')}</th>
                            <th>{t('orderTable.paymentMethod')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {driverOrders.length === 0 &&
                            <tr>
                                <td colSpan={11} style={{ textAlign: 'center' }}>
                                    <ProgressBar />
                                </td>
                            </tr>
                        }
                        {
                            driverOrders.map((order, index) => {
                                const { account, payments } = order;

                                return (
                                    <tr key={index}>
                                        <th>#{order.id}</th>
                                        <th>{account.first_name + " " + account.last_name}</th>
                                        <td>{account.mobile}</td>
                                        <td>{moment(order.created_datetime).format('MM/DD/YYYY')}</td>
                                        <td>{moment(order.created_datetime).format('LT')}</td>
                                        <th>$ {order.final_total}</th>
                                        <th>{payments[0].payment_method === 1 ? 'CARD' : (payments[0].payment_method === 2 ? 'COD' : '---')}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div style={{ marginLeft: '70%', marginTop: '2%' }}>
                <Pagination
                    nextClick={() => {
                        setDriverOrders([]);
                        setPage(page + 1)
                    }}
                    preClick={() => {
                        if (page > 1) {
                            setDriverOrders([])
                            setPage(page - 1)
                        }
                    }}
                />
            </div>
        </>
    )
}

DriverOrders.propTypes = {
    driver: PropTypes.object,
    onBack: PropTypes.func
}

export default DriverOrders;
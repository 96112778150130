import React, { useState } from 'react'
import {
    AccountMenu,
    AccountUsers,
    AccountAdmin,
    AccountDrivers,
    OperatorAdmin
} from './components'
// import { usersData, driversData } from 'src/mockData/management'

const AccountManagement = props => {

    const [accountType, setAccountType] = useState('menu');

    return (
        <>
            {accountType === 'menu' && <AccountMenu onChange={type => setAccountType(type)} />}
            {accountType === 'user' &&
                <AccountUsers onBack={() => setAccountType('menu')} />
            }
            {accountType === 'admin' &&
                <AccountAdmin onBack={() => setAccountType('menu')} />
            }
            {accountType === 'driver' &&
                <AccountDrivers onBack={() => setAccountType('menu')} />
            }
            {accountType === 'operator' &&
                <OperatorAdmin onBack={() => setAccountType('menu')} />
            }
        </>
    )
}

export default AccountManagement;
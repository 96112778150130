import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import { useTranslation } from 'react-i18next'

const UserEdit = props => {

    const { user, onBack, onUpdate, index } = props;
    const { t } = useTranslation();

    const [_user, setUser] = useState(user);

    const onChangeUser = event => {
        const { name, value } = event.target;
        setUser({ ..._user, [name]: value })
    }
    const disabled = () => {
        const { first_name, last_name, mobile, email } = _user;
        if ((!first_name || !last_name) || !mobile || !email) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Back onBack={onBack} />
            <div className='edit_title'>
                <span>{t('id')}</span>
                <span>{user.id}</span>
            </div>
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('firstName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='first_name'
                        value={_user.first_name || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('lastName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='last_name'
                        value={_user.last_name || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='mobile'
                        value={_user.mobile || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={_user.email}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-button'>
                    <Button
                        disabled={disabled()}
                        title={t('update')}
                        onClick={() => onUpdate(_user, index)}
                    />
                </div>
            </div>
        </>
    )
}

UserEdit.propTypes = {
    user: PropTypes.object,
    onBack: PropTypes.func,
    onUpdate: PropTypes.func,
    index: PropTypes.number
}

export default UserEdit;
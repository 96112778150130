import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import { useTranslation } from 'react-i18next'

const ServiceComInvoice = props => {

    const { onBack } = props;
    const { t } = useTranslation();

    const [urlImage, setUrlImage] = useState();

    const onChangeFile = event => {
        setUrlImage(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <Back onBack={onBack} style={{ marginBottom: '0' }} />
                <Button title='Save' />
            </div>
            <div className='store_transaction'>
                <div className='store_transaction-field'>
                    <span>{t('id')}</span>
                    <input />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('orderTable.driverName')}</span>
                    <input />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('date')}</span>
                    <input />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('time')}</span>
                    <input />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('orderTable.paymentMethod')}</span>
                    <div className='store_transaction-pays'>
                        <p>Bank Card</p>
                        <p>COD</p>
                    </div>
                </div>
                <textarea
                    rows='4'
                    className='store_transaction-desc'
                    placeholder='DESCRIPTION'
                />
            </div>
            <div className='commissions'>
                <div className='commissions-title'>
                    <p>{t('orderTable.commission')}</p>
                    <p>{t('create')}</p>
                </div>
                <div className='commissions-detail'>
                    <div className='commissions-box'>
                        <p></p>
                    </div>
                    <div className='commissions-box'>
                        <input className='input_commission' />
                    </div>
                </div>
            </div>
            <div className='mad_service'>
                <div className='image'>
                    <p>{t('image')}</p>
                    <div style={{ display: 'flex' }}>
                        <label htmlFor='commission_image'>
                            <span style={{ display: !urlImage ? 'block' : 'none' }}>{t('browse')}</span>
                            <img alt='' src={urlImage} width='100%' height='auto' style={{ display: urlImage ? 'inline' : 'none' }} />
                        </label>
                        <input
                            onChange={onChangeFile}
                            id='commission_image'
                            type='file'
                            accept='image/*'
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <div className='amount'>
                    <p>{t('amount')} :</p>
                    <div className='amount-type'>
                        <input />
                        <span>MAD</span>
                    </div>
                </div>
            </div>

        </>
    )
}

ServiceComInvoice.propTypes = {
    invoice: PropTypes.object,
    onBack: PropTypes.func
}

export default ServiceComInvoice;
import React, { useEffect, useState } from 'react';
import './SaleOrder.scss'
import { ListOrder, DetailOrder } from './components';

const SaleOrder = (props) => {
    const { order } = props
    const [load, setLoad] = useState('list-order')
    const [orderDetail, setOrderDetail] = useState(null)
    const [driverOfOrder, setDriverOfOrder] = useState(null)

    useEffect(() => {
        if (order !== null) {
            setOrderDetail(order)
            setLoad('detail-order')
        }
    }, [order])

    const viewDetailOrderHandle = (order, driver) => {
        setOrderDetail(order)
        setDriverOfOrder(driver)
        setLoad('detail-order')
    }

    const backHandle = () => {
        setLoad('list-order')
    }

    return (
        <>
            {load === 'list-order' && <ListOrder viewDetailCallBack={viewDetailOrderHandle} />}
            {load === 'detail-order' && <DetailOrder order={orderDetail} driver={driverOfOrder} onBack={backHandle} viewDetailCallBack={viewDetailOrderHandle} />}
        </>
    );
}

export default SaleOrder;
import React from 'react'
import PropTypes from 'prop-types'
import { Back } from 'src/components'
import { useTranslation } from 'react-i18next'

const CourierTransaction = props => {

    const { onBack } = props;
    const { t } = useTranslation();

    return (
        <>
            <Back onBack={onBack} />
            <div>{t('transactionCourier')}</div>
        </>
    )
}

CourierTransaction.propTypes = {
    onBack: PropTypes.func
}

export default CourierTransaction;
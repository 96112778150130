import React, { useEffect, useState } from 'react';
import './NewItem.scss'
import axios from 'src/utils/axios'
import axiosInstance from 'src/utils/axiosInstance';
import { Back, Button } from 'src/components';
import { useTranslation } from 'react-i18next'
import apis from 'src/apiConfig'

const NewItem = (props) => {

    const {onSuccess} = props

    const { t } = useTranslation();
    const [item, setItem] = useState({
        item_images: null,
        item_name: '',
        type: 1,
        food: 1,
        unit_price: '',
        ingredients: '',
        availability: true,
        is_toping: false
    })
    const [itemsParent, setItemsParent] = useState([])
    const [imgReview, setImgReview] = useState('')
    const [idParent, setIdParent] = useState(-1)

    useEffect(() => {
        axiosInstance('/menuItem/list?restaurant=' + localStorage.getItem("restaurant"), 'GET', null)
            .then(res => {
                if (res.data[1] === 201 && res.data[0].status_code === 201) {
                    setItemsParent(res.data[0].data)
                }
            })
            .catch(err => {
                alert("error")
                localStorage.removeItem("token")
                localStorage.removeItem("restaurant")
                window.location.href = window.location
            })
    }, [])


    const btnBackClick = () => {
        props.callBack('store-menu')
    }

    const changeImg = (event) => {
        setItem({ ...item, item_image: event.target.files[0] })
        setImgReview(URL.createObjectURL(event.target.files[0]))
    }

    const change = (event) => {
        setItem({ ...item, [event.target.name]: event.target.value })
    }
    const changeCheckbox = (event) => {
        setItem({ ...item, [event.target.name]: event.target.checked })
    }

    const btnCreateClick = () => {
        if (item.item_image === null) {
            alert("Please choose image for this item")
        }
        else if (item.is_toping === true && idParent === -1) {
            alert("Please choose item parent for topping")
        }
        else if (item.item_name === "" || item.unit_price === "") {
            alert("Please fill all fields")
        }
        else {
            var form = new FormData()
            form.append("restaurants", localStorage.getItem("restaurant"))
            for (var x in item) {
                form.append([x], item[x])
            }
            var urlCreate
            if (!item.is_toping) {
                urlCreate = apis.BASE_URL + '/menuItem/create'
            }
            else {
                urlCreate = apis.BASE_URL + '/menuItem/create?parent=' + idParent
            }
            axios.post(urlCreate, form)
                .then(res => {
                    onSuccess();
                })
                .catch(error => {
                    alert("There are some errors, please try again.")
                });
        }

    }

    const renderItemParentOfTopping = () => {
        if (item.is_toping === true) {
            return (
                < select onChange={setParentForTopping}>
                    <option value={-1}>--Select Item--</option>
                    {
                        itemsParent.map((it) => {
                            return <option key={it.id} value={it.id}>{it.item_name}</option>
                        })
                    }
                </select>
            )
        }
    }

    const setParentForTopping = (event) => {
        setIdParent(event.target.value)
    }

    return (
        <div className="new-item">
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button> */}
                <Back onBack={btnBackClick} />
            </div>
            <div className="store">
                <div className="head">
                    <span>{t('newItem')}</span>
                </div>
                <div className="body">
                    <div className="picture">
                        <img src={imgReview||'/images/no-image.png'} alt='img' />
                        <input type="file" onChange={changeImg} />
                    </div>
                    <div className="infor">
                        <div className="form">
                            <span>{t('name')}: </span>
                            <input className="enable" name="item_name" type="text" value={item.item_name} onChange={change} />
                        </div>
                        <div className="form">
                            <span>{t('type')}: </span>
                            <select className='enable' name='type' value={item.type} onChange={change}>
                                <option value={1} >Starter</option>
                                <option value={2} >Meal</option>
                                <option value={3} >Dessert</option>
                            </select>
                        </div>
                        <div className="form">
                            <span>{t('price')}: </span>
                            <input className="enable" name="unit_price" type="number" min={0} value={item.unit_price} onChange={change} />
                        </div>
                        <div className="form">
                            <span>{t('ingredients')}: </span>
                            <textarea className="enable" name="ingredients" value={item.ingredients} onChange={change}></textarea>
                        </div>
                        <div className="form">
                            <span>{t('available')}: </span>
                            <input className="enable" name="availability" type="checkbox" checked={item.availability} onChange={changeCheckbox} />
                        </div>
                        <div className="form">
                            <span>{t('topping')}: </span>
                            <input className="enable" name="is_toping" type="checkbox" checked={item.is_toping} onChange={changeCheckbox} />
                            {renderItemParentOfTopping()}
                        </div>
                        {/* <button onClick={btnCreateClick}>Create Item</button> */}
                        <Button title={t('create')} onClick={btnCreateClick} />
                    </div>
                </div>

            </div>
        </div >
    );
}

export default NewItem;
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Back, ProgressBar } from 'src/components'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { getRestaurant } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'

const ListTransactionStore = props => {

    const { onBack, onClick } = props;
    const { t } = useTranslation();

    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const fetchTransactions = () => {
            axios.get(apis.BASE_URL + apis.ORDER_PAYMENTS + getRestaurant())
                .then(res => setTransactions(res.data[0].data))
        }
        fetchTransactions();
    }, [])

    return (
        <div className='invoices'>
            <Back onBack={onBack} />
            {transactions.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {transactions.map(tran => (
                <p className='invoice_list-item' onClick={() => onClick(tran)}>
                    <span className='driver_list-title'>{t('orderTable.order')}: {tran.sale_order || ''}</span>
                    <span className='driver_list-title'>
                        {t('date')}: {(tran.payment_datetime && moment(tran.payment_datetime).format('MM/DD/YYYY')) || ''}
                    </span>
                    <span className='driver_list-title'>
                        {t('orderTable.paymentMethod')}: {tran.payment_method === 1 ? 'CARD' : (tran.payment_method === 2 ? 'COD' : '')}
                    </span>
                    <span className='driver_list-title'>{t('amount')}: {tran.amount || ''}</span>
                </p>
            ))}
        </div>
    )
}

ListTransactionStore.propTypes = {
    onBack: PropTypes.func,
    onClick: PropTypes.func
}

export default ListTransactionStore;
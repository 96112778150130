import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

const Button = props => {

    const { title, disabled, color, onClick, className, style } = props;

    return (
        <button
            style={{
                ...style,
                backgroundColor: color,
                opacity: disabled ? 0.7 : 1,
                pointerEvents: disabled ? 'none' : 'auto'
            }}
            className={`${styles.button} ${className}`}
            onClick={onClick}>
            {title}
        </button>
    )
}

Button.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}

Button.defaultProps = {
    disabled: false,
    color: '#e82d86',
    onClick: () => { },
    className: '',
    style: {}
}

export default Button;
import React, { useState } from 'react'
import {
    TransactionMenu,
    ListTransactionStore,
    TransactionStore,
    TransactionOrder,
    TransactionCourier
} from './components'

const TransactionManagement = props => {

    const [transactionType, setTransactionType] = useState('menu');
    const [transaction, setTransaction] = useState();

    const showTransactionDetail = transaction => {
        setTransaction(transaction);
        setTransactionType('transaction-detail');
    }
    const showOrderDetail = saleOrder => {
        setTransactionType('order');
    }

    return (
        <>
            {transactionType === 'menu' && <TransactionMenu onChange={type => setTransactionType(type)} />}
            {transactionType === 'transaction-list' &&
                <ListTransactionStore
                    onBack={() => setTransactionType('menu')}
                    onClick={showTransactionDetail}
                />
            }
            {transactionType === 'transaction-detail' &&
                <TransactionStore
                    onBack={() => setTransactionType('transaction-list')}
                    transaction={transaction}
                    showOrderDetail={showOrderDetail}
                />
            }
            {transactionType === 'order' &&
                <TransactionOrder
                    onBack={() => setTransactionType('transaction-detail')}
                    saleOrder={transaction.sale_order}
                />
            }
            {transactionType === 'courier' && <TransactionCourier onBack={() => setTransactionType('menu')} />}
        </>
    )
}

export default TransactionManagement;
import React from 'react'
import PropTypes from 'prop-types'
import styles from './Input.module.scss'

const Input = props => {

    const {
        type, name, placeholder,
        value, onChange, onClick,
        onKeyDown, error, errorText,
        fieldClassName, className, style,
        disabled, rest
    } = props;

    return (
        <div className={fieldClassName} style={disabled ? { opacity: 0.7, pointerEvents: 'none' } : null}>
            <input
                {...rest}
                type={type}
                name={name}
                placeholder={placeholder}
                style={style}
                className={`${styles.input} ${className}`}
                value={value}
                onChange={onChange}
                onClick={onClick}
                onKeyDown={e => onKeyDown(e)}
            />
            <p className={styles.error}
                style={{ display: error ? (errorText ? 'block' : 'none') : 'none' }}>
                {errorText}
            </p>
        </div>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    fieldClassName: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object
}
Input.defaultProps = {
    type: 'text',
    name: '',
    placeholder: '',
    value: '',
    onChange: () => { },
    onClick: () => { },
    onKeyDown: () => { },
    error: false,
    errorText: '',
    fieldClassName: '',
    className: '',
    disabled: false,
    style: {}
}

export default Input;
import React, { useEffect, useState } from 'react'
import { Alert } from 'src/components'
import {
    PromotionList,
    PromotionAdd
} from './components'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'

const PromotionManagement = props => {

    const [promotionType, setPromotionType] = useState('list');
    const [coupons, setCoupons] = useState([]);
    const [coupon, setCoupon] = useState(null);
    const [reload, setReload] = useState(false);
    const [alert, setAlert] = useState({
        type: '', open: false, msg: ''
    })

    const setAddCoupon = () => {
        setCoupon(null);
        setPromotionType('add')
    }
    const onCreateCoupon = formData => {
        axios.post(apis.BASE_URL + apis.PROMOTION_ADD, formData)
            .then(() => {
                setPromotionType('list');
                setAlert({ type: 'success', open: true, msg: 'Create successfully.' });
                setReload(!reload);
            })
            .catch(() => {
                setPromotionType('list');
                setAlert({ type: 'error', open: true, msg: 'Create failed, please try again.' })
            })
    }
    const setUpdateCoupon = coupon => {
        setCoupon(coupon);
        setPromotionType('add')
    }
    const onUpdateCoupon = (formData, id) => {
        axios.patch(apis.BASE_URL + apis.PROMOTION_UPDATE + id, formData)
            .then(() => {
                setPromotionType('list');
                setAlert({ type: 'success', open: true, msg: 'Update successfully.' });
                setReload(!reload);
            })
            .catch(() => {
                setPromotionType('list');
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' })
            })
    }
    const onRemoveCoupon = (id, index) => {
        axios.delete(apis.BASE_URL + apis.PROMOTION_DELETE + id)
            .then(() => {
                const _coupons = [...coupons];
                _coupons.splice(index, 1);
                setCoupons(_coupons);
                setAlert({ type: 'success', open: true, msg: 'Delete successfully.' });
            })
            .catch(() => {
                setAlert({ type: 'error', open: true, msg: 'Delete failed, please try again.' });
            })
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.PROMOTIONS)
            .then(res => setCoupons(res.data[0].data))
    }, [reload])

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                msg={alert.msg}
                onClose={() => setAlert({ ...alert, open: false })}
            />
            {promotionType === 'list' &&
                <PromotionList
                    coupons={coupons}
                    add={setAddCoupon}
                    update={setUpdateCoupon}
                    remove={onRemoveCoupon}
                />
            }
            {promotionType === 'add' &&
                <PromotionAdd
                    onBack={() => setPromotionType('list')}
                    coupon={coupon}
                    add={onCreateCoupon}
                    update={onUpdateCoupon}
                />
            }
        </>
    )
}

export default PromotionManagement;
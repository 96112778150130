import React from 'react'
import PropTypes from 'prop-types'
import styles from './ProgressBar.module.scss'

const ProgressBar = props => {

    const { size, style } = props;

    return (
        <div style={{ ...style, width: size + 'px', height: size + 'px' }}
            className={styles.sk_chase}>
            <div className={styles.sk_chase_dot}></div>
            <div className={styles.sk_chase_dot}></div>
            <div className={styles.sk_chase_dot}></div>
            <div className={styles.sk_chase_dot}></div>
            <div className={styles.sk_chase_dot}></div>
            <div className={styles.sk_chase_dot}></div>
        </div>
    )
}

ProgressBar.propTypes = {
    size: PropTypes.number,
    style: PropTypes.object
}
ProgressBar.defaultProps = {
    size: 32,
    style: {}
}

export default ProgressBar;
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { OperatorList, OperatorAdd, OperatorEdit, UserAdd, Assign } from './components'
import { Alert } from 'src/components'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'

const OperatorAdmin = props => {

    const { onBack } = props;

    const [alert, setAlert] = useState({
        type: 'success', open: false, msg: ''
    });
    const [showType, setShowType] = useState('list');
    const [operators, setOperators] = useState([]);
    const [operator, setOperator] = useState();
    const [index, setIndex] = useState();

    const setEditOperator = (operator, index) => {
        setOperator(operator);
        setIndex(index);
        setShowType('edit');
    }
    const onUpdateOperator = (user, index) => {
        axios.patch(apis.BASE_URL + apis.USER_ACCOUNT_UPDATE + user.id, user)
            .then(() => {
                const _operators = [...operators];
                _operators[index].user = user;
                setOperators(_operators);
                setShowType('list');
                setAlert({ type: 'success', open: true, msg: 'Update successfully.' })
            })
            .catch(() => {
                setShowType('list');
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' })
            })
    }
    const onDeleteOperator = (operator, index) => {
        axios.delete(apis.BASE_URL + apis.OPERATOR_ACCOUNT_DELETE + operator.id)
            .then(() => {
                const _operators = [...operators];
                _operators.splice(index, 1);
                setOperators(_operators);
                setAlert({ type: 'success', open: true, msg: 'Delete successfully.' })
            })
            .catch(() => setAlert({ type: 'error', open: true, msg: 'Delete failed, please try again.' }))
    }

    useEffect(() => {
        const fetchOperators = () => {
            axios.get(apis.BASE_URL + apis.OPERATOR_ACCOUNTS)
                .then(res => setOperators(res.data))
        }
        fetchOperators();
    }, [])

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            {showType === 'list' &&
                <OperatorList
                    data={operators}
                    onBack={onBack}
                    onClick={() => setShowType('add')}
                    onClickAddUser={() => setShowType('addUser')}
                    onClickAssign={() => setShowType('assign')}
                    onEdit={setEditOperator}
                    onDelete={onDeleteOperator}
                />
            }
            {showType === 'add' &&
                <OperatorAdd
                    onBack={() => setShowType('list')}
                    onAdd={operator => {
                        setOperators([...operators, operator]);
                        setShowType('list')
                    }}
                />
            }
            {showType === 'edit' &&
                <OperatorEdit
                    operator={operator}
                    index={index}
                    onBack={() => setShowType('list')}
                    onUpdate={onUpdateOperator}
                />
            }
            {showType === 'addUser' &&
                <UserAdd
                    onBack={() => setShowType('list')}
                />
            }
            {showType === 'assign' &&
                <Assign
                    onBack={() => setShowType('list')}
                />
            }
        </>
    )
}

OperatorAdmin.propTypes = {
    onBack: PropTypes.func
}
OperatorAdmin.defaultProps = {
    onBack: () => { }
}

export default OperatorAdmin;
import React, { useState } from 'react'
import { Input, Radio, Button } from 'src/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faLock } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next'

const CustomInputDatepicker = ({ value, onClick }) => {
    return (
        <div className='payment_iconBox'>
            <FontAwesomeIcon icon={faCalendar} color='#929292' />
            <Input
                fieldClassName='payment_input'
                className='input'
                name='expirationDate'
                value={value}
                onClick={onClick}
            />
        </div>
    )
}

const PaymentManagement = props => {

    const { t } = useTranslation();
    const [payment, setPayment] = useState({
        payment_method: 'Visa'
    });
    // const [alert, setAlert] = useState({ type: '', open: false, msg: '' })

    const onChangePayment = event => {
        const { name, value } = event.target;
        setPayment({ ...payment, [name]: value })
    }
    const onSavePayment = () => {

    }

    return (
        <>
            <div className='payment'>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.commandId')}</span>
                    <Input
                        fieldClassName='payment_input'
                        className='input'
                        type='password'
                        name='commandID'
                        value={payment.commandID || ''}
                        onChange={onChangePayment}
                    />
                </div>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.paymentMethod')}</span>
                    <div className='payment_method' onClick={() => setPayment({ ...payment, payment_method: 'Visa' })}>
                        <Radio
                            size={18}
                            color="#e82d86"
                            checked={payment.payment_method === 'Visa'}
                        />
                        <img alt='' src='/images/visa.png' />
                    </div>
                    <div className='payment_method' onClick={() => setPayment({ ...payment, payment_method: 'Master Card' })}>
                        <Radio
                            size={18}
                            color="#e82d86"
                            checked={payment.payment_method === 'Master Card'}
                        />
                        <img alt='' src='/images/master-card.png' />
                    </div>
                </div>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.cardNumber')}</span>
                    <div className='payment_iconBox' style={{ width: '40%' }}>
                        <FontAwesomeIcon icon={faCreditCard} color='#929292' />
                        <Input
                            fieldClassName='payment_input'
                            className='input'
                            type='password'
                            name='cardNumber'
                            value={payment.cardNumber || ''}
                            onChange={onChangePayment}
                        />
                    </div>
                </div>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.expirationDate')}</span>
                    <DatePicker
                        selected={payment.expirationDate ? new Date(payment.expirationDate) : null}
                        onChange={date => setPayment({ ...payment, expirationDate: moment(date).format('MM/DD/YYYY') })}
                        customInput={<CustomInputDatepicker />}
                    />
                </div>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.securityCode')}</span>
                    <div className='payment_iconBox'>
                        <FontAwesomeIcon icon={faLock} color='#929292' />
                        <Input
                            fieldClassName='payment_input'
                            className='input'
                            type='password'
                            name='securityCode'
                            value={payment.securityCode || ''}
                            onChange={onChangePayment}
                        />
                    </div>
                </div>
                <div className='payment_field'>
                    <span className='payment_title'>{t('paymentDetail.saveMyCardNumber')}</span>
                    <Button title={t('save')} onClick={onSavePayment} />
                </div>
            </div>

            {/* <Alert
                type={alert.type}
                open={alert.open}
                msg={alert.msg}
            /> */}
        </>
    )
}

export default PaymentManagement;
export const getToken = () => {
    const token = localStorage.getItem('token');
    return token;
}
export const getRestaurant = () => {
    const restaurant = localStorage.getItem('restaurant');
    return restaurant;
}
export const getUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user)
    } else {
        return {}
    }
}

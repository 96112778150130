import React from 'react'
import PropTypes from 'prop-types'
import User from './User'

const UserList = props => {

    const { open, users, onChangeUser } = props;

    return (
        <div className='users_to_chat-overlay' style={open ? { height: '280px' } : null}>
            <div className='users_to_chat'>
                {users.map((user, index) => (
                    <User key={index} user={user} onClick={() => onChangeUser(user)} />
                ))}
            </div>
        </div>
    )
}

UserList.propTypes = {
    open: PropTypes.bool,
    users: PropTypes.arrayOf(Object),
    onChangeUser: PropTypes.func
}
UserList.defaultProps = {
    open: false,
    users: [],
    onChangeUser: () => { }
}

export default UserList;
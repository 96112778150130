import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './Alert.module.scss'

const Alert = props => {

    const { type, msg, open, onClose, duration, className, style } = props;

    useEffect(() => {
        const close = () => {
            setTimeout(onClose, duration)
        }
        if (open) {
            close();
        }
        return () => clearTimeout(close);
    }, [open, duration, onClose])

    return (
        <p className={`${styles.alert} ${className}`}
            style={
                {
                    ...style,
                    backgroundColor: type === 'success' ? '#1bb510' : '#e82d86',
                    visibility: open ? 'visible' : 'hidden',
                    opacity: open ? 1 : 0
                }
            }>
            {msg}
        </p>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'error']),
    msg: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    duration: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object
}
Alert.defaultProps = {
    type: 'success',
    msg: '',
    open: false,
    onClose: null,
    duration: 2000,
    className: '',
    style: {}
}

export default Alert;
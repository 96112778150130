import axios from 'axios'
import { getToken } from './localStorageService'

const instance = axios.create({
    headers: {
        'Authorization': 'JWT ' + getToken()
    }
})

export default instance;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button, Radio } from 'src/components'
import { useTranslation } from 'react-i18next'

const DriverEdit = props => {

    const { t } = useTranslation();

    const { driver, onBack, onUpdate, index } = props;

    const [formdata, setFormdata] = useState(driver);

    const onChangeDriver = event => {
        const { name, value } = event.target;
        if (name === 'commission') {
            setFormdata({
                ...formdata,
                commission: value
            });
            return;
        }
        setFormdata({
            ...formdata,
            user: {
                ...formdata.user,
                [name]: value
            }
        })
    }
    const disabled = () => {
        const { first_name, last_name, mobile, email } = formdata.user;
        if ((!first_name || !last_name) || !mobile || !email) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Back onBack={onBack} />
            <div className='edit_title'>
                <span>{t('id')}</span>
                <span>{driver.id}</span>
            </div>
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('firstName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='first_name'
                        value={formdata.user.first_name || ''}
                        onChange={onChangeDriver}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('lastName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='last_name'
                        value={formdata.user.last_name || ''}
                        onChange={onChangeDriver}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='mobile'
                        value={(formdata.user && formdata.user.mobile) || ''}
                        onChange={onChangeDriver}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={(formdata.user && formdata.user.email) || ''}
                        onChange={onChangeDriver}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('orderTable.commission')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='commission'
                        value={(formdata && formdata.commission) || ''}
                        onChange={onChangeDriver}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('status')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <div className='edit_form-radioGroup'>
                        <Radio
                            label={t('active')}
                            color='#e82d86'
                            checked={formdata.is_online}
                            onSelect={() => setFormdata({ ...formdata, is_online: true })}
                        />
                        <Radio
                            label={t('inactive')}
                            color='#e82d86'
                            checked={!formdata.is_online}
                            onSelect={() => setFormdata({ ...formdata, is_online: false })}
                        />
                    </div>
                </div>
                <div className='edit_form-button'>
                    <Button
                        disabled={disabled()}
                        title={t('update')}
                        onClick={() => onUpdate(formdata, index)}
                    />
                </div>
            </div>
        </>
    )
}

DriverEdit.propTypes = {
    driver: PropTypes.object,
    onBack: PropTypes.func,
    onUpdate: PropTypes.func,
    index: PropTypes.number
}

export default DriverEdit;
import React, { useState } from 'react';
import './DetailOrder.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { Alert, Back, Badge, Button } from 'src/components';
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { useTranslation } from 'react-i18next'
import { getUser } from 'src/utils/localStorageService'

const DetailOrder = (props) => {



    const { t } = useTranslation()
    // const [editing, setEditing] = useState(false)
    // const [classInput, setClassInput] = useState('disable')
    // const [display, setDisplay] = useState('none')

    const { is_superuser } = getUser()
    const [alert, setAlert] = useState({
        type: 'success',
        msg: '',
        open: false
    })
    //allowToEffect: Role for edit(accept or cancel) order
    const { order, driver } = props
    const d = new Date(order.created_datetime);

    const btnBackClick = () => {
        props.onBack()
    }

    const renderPayment = () => {
        if (order.payments !== undefined && order.payments.length > 0 && order.payments.payment_method !== null) {
            if (order.payments.payment_method === 1) return <p className='value'  >CARD</p>
            return <p className='value'  >COD</p>
        }
        return <p className='value'  >-</p>
    }

    const btnAcceptClick = () => {
        axios.post(apis.BASE_URL + apis.SALE_ORDER_CONFIRM,
            {
                "sale_order": order.id,
                "status": 1,
                "driver": null,
                "time": new Date().toISOString(),
                "longitude": null,
                "latitude": null
            }
        )
            .then(res => {
                props.onBack()
            })
    }

    const btnCancelClick = () => {
        axios.post(apis.BASE_URL + apis.SALE_ORDER_CANCEL,
            {
                "sale_order": order.id,
                "status": -99,
                "driver": null,
                "time": new Date().toISOString(),
                "longitude": null,
                "latitude": null
            }
        )
            .then(res => {
                props.onBack()
            })
    }

    return (
        <div className="detail-branch">
            <Alert type={alert.type} msg={alert.msg} open={alert.open} onClose={() => { setAlert({ type: 'success', msg: '', open: false }) }} />
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button> */}
                <Back onBack={btnBackClick} />
            </div>
            <div className="branch">
                <div className="head" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Detail Order</span>
                    {
                        (order.trackings !== undefined && order.trackings[order.trackings.length - 1].status === -99)
                        &&
                        <div>
                            <Badge type="cancel" content="Canceled" />
                        </div>
                    }
                </div>
                <div className="body">
                    <div className="left">
                        <div className="form">
                            <span>{t('orderTable.order')}: </span>
                            <p className='value'>#{order.id}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.customerName')}: </span>
                            <p className='value' >{order.account.first_name !== undefined && order.account.first_name + " " + order.account.last_name}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.customerPhone')}: </span>
                            <p className='value'  >{order.account.mobile}</p>
                        </div>
                        <div className="form">
                            <span>{t('date')}: </span>
                            <p className='value'  >{d.getDay() + 1}/{d.getMonth()}/{d.getFullYear()}</p>
                        </div>
                        <div className="form">
                            <span>{t('time')}: </span>
                            <p className='value'  >{d.getHours()}h {d.getMinutes()}m</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.promoCode')}: </span>
                            <p className='value' >{order.promo_code} </p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.note')}: </span>
                            <p className='value'  >{order.note}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.internalCode')}: </span>
                            <p className='value'  >{order.internal_note}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.driverName')}: </span>
                            <p className='value'  >{driver !== null && driver.user.first_name + " " + driver.user.last_name}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.driverPhone')}: </span>
                            <p className='value'  >{(driver !== null && driver.user.mobile) || "-"}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.commission')}: </span>
                            <p className='value'  >{(driver !== null && driver.commission) || "-"}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.paymentMethod')}: </span>
                            {renderPayment()}
                        </div>
                    </div>
                    <div className="right">
                        <div className="form">
                            <span>{t('orderTable.subTotal')}: </span>
                            <p className='value'  >$ {order.subtotal}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.feeTotal')}: </span>
                            <p className='value' >${order.fee_total}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.taxTotal')}: </span>
                            <p className='value'  >${order.tax_total}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.chippingFee')}: </span>
                            <p className='value'  >${order.chipping_fee}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.discount')}: </span>
                            <p className='value'  >${order.discount}</p>
                        </div>
                        <div className="form">
                            <span>{t('orderTable.total')}: </span>
                            <p className='value'  >${order.final_total}</p>
                        </div>
                        {
                            order.restaurant_branch.restaurants !== undefined
                            &&
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10%' }}>
                                    <img src={apis.BASE_URL + order.restaurant_branch.restaurants.restaurant_image} alt='img' style={{ width: '90%', height: 'auto', marginBottom: '5%' }} />
                                    <span style={{ marginBottom: '5%', fontWeight: '500' }}><FontAwesomeIcon icon={faStore} /> {order.restaurant_branch.restaurants.name}</span>
                                </div>
                                <div className="form">
                                    <span>Branch: </span>
                                    <p className='value'  >{order.restaurant_branch.street_address + " " + order.restaurant_branch.city_province}</p>
                                </div>
                            </>
                        }

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                            {!is_superuser && (!order.trackings || (order.trackings && order.trackings[order.trackings.length - 1].status !== 1)) ?
                                <Button title="Accept" onClick={btnAcceptClick} /> : null
                            }
                            {!is_superuser && (!order.trackings || (order.trackings && order.trackings[order.trackings.length - 1].status !== -99)) ?
                                <Button title="Cancel" color="darkgray" onClick={btnCancelClick} /> : null
                            }
                        </div>
                    </div>
                    {
                        (driver !== null && order.trackings[order.trackings.length - 1].status !== -99 && order.trackings[order.trackings.length - 1].status !== -1)
                        &&
                        <iframe
                            title='order-location'
                            style={{ marginTop: '2%' }}
                            width="100%"
                            height="300"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            src={'https://maps.google.com/maps?q=' + driver.latitude + ',' + driver.longitude + '&t=&z=15&ie=UTF8&iwloc=&output=embed'}
                        />
                    }

                </div>
            </div>
        </div>
    );
}

export default DetailOrder;
import React from 'react'
import PropTypes from 'prop-types'
import styles from './Title.module.scss'

const Title = props => {
    const { title, className, style } = props;
    return (
        <p className={`${styles.title} ${className}`} style={style}>
            {title}
        </p>
    )
}

Title.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
}
Title.defaultProps = {
    title: 'Header 1',
    className: '',
    style: {}
}

export default Title;
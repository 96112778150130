import React, { useState } from 'react';
import { ListStores, AddStore, EditStore } from './components'

const StoreManagement = (props) => {
    const [load, setLoad] = useState('list-stores')
    const [detailStore,setDetailStore] = useState({})

    const loadPage = (page) =>{
        setLoad(page)
    }

    const viewDetailCallbackHandle=(Store)=>{
        setLoad('edit-store');
        setDetailStore(Store)
    }

    return (
        <>
            {load === 'list-stores' && <ListStores changePageCallback = {loadPage} viewDetailCallback={viewDetailCallbackHandle}/>}
            {load === 'add-store' && <AddStore onBack={()=>{setLoad('list-stores')}} />}
            {load === 'edit-store' && <EditStore restaurant={detailStore} onBack={()=>{setLoad('list-stores')}} />}
        </>
    )
}

export default StoreManagement;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import 'src/views/StoreConfig/component/TabPanes/SaleOrder/components/ListOrder/ListOrder.scss'
import TableOrders from './TableOrders'
import {DetailOrder} from 'src/views/StoreConfig/component/TabPanes/SaleOrder/components'

const ServiceOrder = props => {
    const { onBack } = props;
    const [page,setPage] = useState('table-orders')
    const [orderDetail, setOrderDetail] = useState({})
    const [driverOfOrder,setDriverOfOrder] = useState(null)

    const loadDetailHandle = (order,driver) =>{
        setOrderDetail(order); 
        setDriverOfOrder(driver)
        setPage('order-detail')
    }

    return (
        <>
            {page==='table-orders' && <TableOrders onBack={onBack} onLoadDetail={loadDetailHandle}  />}
            {page==='order-detail' && <DetailOrder onBack={()=>setPage('table-orders')}  order={orderDetail} driver={driverOfOrder}/>}
        </>
    )
}

ServiceOrder.propTypes = {
    onBack: PropTypes.func
}

export default ServiceOrder;
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './Login.scss'
import axios from 'axios'
import apis from 'src/apiConfig'
import { Auth, Input, Button, Alert } from 'src/components'
import { useTranslation } from 'react-i18next'

// {
//     "device": "admin@webili.com",
//     "password": "qwerty123456"
// }

const Login = ({ history }) => {

    const { t } = useTranslation();

    const [loginForm, setLoginForm] = useState({
        device: "admin@webili.com",
        password: "qwerty123456"
    });
    const [showPasswd, setShowPasswd] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })

    const onChangeLogin = event => {
        const { name, value } = event.target;
        setLoginForm({ ...loginForm, [name]: value });
    }
    const onShowPassword = () => {
        setShowPasswd(!showPasswd);
    }
    const onLogin = () => {
        setDisabled(true)
        axios.post(apis.BASE_URL + apis.LOGIN, loginForm)
            .then(res => {
                if (res.data[0].status) {
                    localStorage.setItem("token", res.data[0].data.token);
                    localStorage.setItem('user', JSON.stringify(res.data[0].data.user))
                    localStorage.setItem("restaurant", res.data[0].data.restaurant_id);
                    history.push('/store-config')
                } else {
                    setDisabled(false);
                    setAlert({type: 'error', open: true, msg: res.data[0].message})
                }
            })
    }

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <Auth />
            <img src='images/logo.png' alt='logo' width='20%' />
            <p className='login_title'>{t('login')}</p>
            <div className='login_form'>
                <Input
                    fieldClassName='fieldInput'
                    style={{ height: '45px' }}
                    name='device'
                    type='text'
                    placeholder='User ID'
                    value={loginForm.device || ''}
                    onChange={onChangeLogin}
                />
                <div className='login_passwd'>
                    <FontAwesomeIcon icon={faLock} />
                    <Input
                        className='inputPass'
                        style={{ height: '45px' }}
                        name='password'
                        type={showPasswd ? 'text' : 'password'}
                        placeholder='Password'
                        value={loginForm.password}
                        onChange={onChangeLogin}
                    />
                    <FontAwesomeIcon
                        icon={showPasswd ? faEyeSlash : faEye}
                        onClick={onShowPassword}
                    />
                </div>
                <Button disabled={disabled} title={t('login')} onClick={onLogin} />
            </div>
        </>
    )
}

export default Login;

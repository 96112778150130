import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Back, Button } from 'src/components'
import { useTranslation } from 'react-i18next'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'

const UserNew = props => {

    const { onBack, onSuccess } = props;
    const { t } = useTranslation();
    const [alert, setAlert] = useState({
        type: 'success', open: false, msg: ''
    });

    const [user, setUser] = useState({ facebook_id: "", google_id: "", });
    const [validatePW,setValidatePW] = useState(false);

    const onChangeUser = event => {
        const { name, value } = event.target;

        if(name==='password')
            if(value.length >= 8) setValidatePW(true);
            else setValidatePW(false);

        setUser({ ...user, [name]: value })
    }
    const disabled = () => {
        const { first_name, last_name, mobile, email, password } = user;
        if ((!first_name || !last_name) || !mobile || !email || !password || !validatePW) {
            return true;
        } else {
            return false;
        }
    }


    const onAddUser = () => {
        axios.post(apis.BASE_URL + apis.USER_ACCOUNT_NEW, user)
            .then(res => {
                console.log(res.data)
                const profile = {
                    photo: null,
                    account: res.data[0].data.id
                }
                axios.post(apis.BASE_URL + apis.USER_PROFILE_NEW, profile)
                    .then(res => {
                        setAlert({ type: 'success', open: true, msg: 'Create successfully.' });
                        onSuccess()
                    })
                    .catch(() => setAlert({ type: 'error', open: true, msg: 'Create failed, please try again.' }))
            })
            .catch(() => setAlert({ ...alert, type: 'error', open: true, msg: 'Phone number of restaurants already exists' }) )
    }

    return (
        <>
        <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('firstName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='first_name'
                        value={user.first_name || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('lastName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='last_name'
                        value={user.last_name || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('password')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='password'
                        value={user.password || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <span style={{ color: '#3a414185', fontSize: '12px', marginBottom: '0.8rem' }}>{t('invalidPassword')}</span>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='mobile'
                        value={user.mobile || ''}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={user.email}
                        onChange={onChangeUser}
                    />
                </div>
                <div className='edit_form-button'>
                    <Button
                        disabled={disabled()}
                        title={t('create')}
                        onClick={() => onAddUser()}
                    />
                </div>
            </div>
        </>
    )
}

UserNew.propTypes = {
    onBack: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default UserNew;
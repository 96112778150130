import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import './Incoming.scss'
import { useTranslation } from 'react-i18next'

const customStyles = {
    content: {
        textAlign: 'center',
        width: '300px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const OrderIncoming = (props) => {
    const { t } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(true);
    const [ordersIncoming, setOrdersIncoming] = useState([]);
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.SALE_ORDERS_INCOMING_ALL_RESTAURANTS)
            .then(res => {
                if (res.data[0].data.length > 0) {
                    setOrdersIncoming(res.data[0].data)
                    setIsOpen(true)
                }
            })
    }, [])

    useEffect(() => {
        const fetchOrderIncoming = () => {
            axios.get(apis.BASE_URL + apis.SALE_ORDERS_INCOMING_ALL_RESTAURANTS)
                .then(res => {
                    if (res.data[0].data.length > 0) {
                        setOrdersIncoming(res.data[0].data)
                        setIsOpen(true)
                    }
                })
        };
        setInterval(fetchOrderIncoming, 60000);
        return () => clearInterval(fetchOrderIncoming)
    })

    function afterOpenModal() {
    }

    function closeModal() {
        setShowModal(false);
        setIsOpen(false);
    }

    const viewOrderDetail = order => {
        setShowModal(false);
        setIsOpen(false);
        props.incomingCallback(order);
    }

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={showModal && modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                <h5 style={{ textAlign: 'center' }}>{t('newOrders')}:</h5>
                {ordersIncoming.map(order => (
                    <p key={order.id} onClick={() => viewOrderDetail(order)} className="order_incoming">#{order.id}</p>
                ))}
                <Button title={t('close')} onClick={closeModal} />
            </Modal>
        </div>
    );
}
export default OrderIncoming;
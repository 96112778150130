import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Back, ProgressBar } from 'src/components'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { getRestaurant } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'

const InvoiceList = props => {

    const { onBack, onClick } = props;
    const { t } = useTranslation();

    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        const fetchInvoices = () => {
            axios.get(apis.BASE_URL + apis.ORDER_PAYMENTS + getRestaurant())
                .then(res => setInvoices(res.data[0].data))
        }
        fetchInvoices();
    }, [])

    return (
        <div className='invoices'>
            <Back onBack={onBack} />
            {invoices.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {invoices.map(invoice => (
                <p className='invoice_list-item' onClick={() => onClick(invoice)}>
                    <span className='driver_list-title'>{t('orderTable.order')}: {invoice.sale_order || ''}</span>
                    <span className='driver_list-title'>
                        {t('date')}: {(invoice.payment_datetime && moment(invoice.payment_datetime).format('MM/DD/YYYY')) || ''}
                    </span>
                    <span className='driver_list-title'>{t('paymentDetail.paymentMethod')}: {invoice.payment_method || ''}</span>
                    <span className='driver_list-title'>{t('amount')}: {invoice.amount || ''}</span>
                </p>
            ))}
        </div>
    )
}

InvoiceList.propTypes = {
    onBack: PropTypes.func,
    onClick: PropTypes.func
}

export default InvoiceList;
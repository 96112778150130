import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { getUser } from 'src/utils/localStorageService'
import { Alert, Select } from 'src/components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ModalAccount from './ModalAccount'


const user = localStorage.getItem('user');
const Header = props => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const user = getUser();
    const { ln } = useSelector(state => state.languageReducer);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })

    //for modal change password
    const [showModalAccount, setShowModalAccount] = useState(false)

    const onChangeLanguage = val => {
        dispatch({ type: 'CHANGE_LANGUAGE', language: val });
        i18n.changeLanguage(val.substring(0, 2).toLowerCase())
    }
    const onSignOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('restaurant');
        history.replace('/login');
    }

    const btnAccountClick = () =>{
        setShowModalAccount(true)
    }

    const modalAccountOnBack=(msg)=>{
        if(msg===true){
            setShowModalAccount(false)
            setAlert({ ...alert, type: 'success', open: true, msg: 'Change password successfully!' })
        }
        else{
            setShowModalAccount(false)
            setAlert({ ...alert, type: 'error', open: true, msg: 'Change password failed!' })
        }
    }

    return (
        <div className='header'>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <ModalAccount show={showModalAccount} onClose={() => { setShowModalAccount(false) }} onBack={modalAccountOnBack}/>
            <div className='header_logo'>
                <img src='images/logo.png' alt='logo' />
            </div>
            <div className='header_sidebar'>
                <div className='header_hover'>
                    <p className='header_userDisplay'>
                        {`${(user.first_name && user.first_name[0]) || ''}${(user.last_name && user.last_name[0]) || ''}`.toUpperCase()}
                    </p>
                    <div className='header_options'>
                        <p className='header_option' onClick={btnAccountClick}>
                            <FontAwesomeIcon icon={faUser} color='#e82d86' style={{ marginRight: '5px' }} />
                            {`${user.first_name || ''} ${user.last_name || ''}`}
                        </p>
                        <p className='header_option' onClick={onSignOut}>
                            <FontAwesomeIcon icon={faSignOutAlt} color='#e82d86' style={{ marginRight: '5px' }} />
                            {t('logout')}
                        </p>
                    </div>
                </div>
                <Select
                    className='languageSelect'
                    classNameSelected='languageSelected'
                    options={['English', 'French', 'Arabic']}
                    value={ln}
                    onChange={onChangeLanguage}
                />
            </div>
        </div>
    )
}

export default Header;
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DriverList, DriverEdit, DriverOrders } from './components'
import { Alert } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'

const AccountDrivers = props => {

    const { onBack } = props;

    const [alert, setAlert] = useState({
        type: 'success', open: false, msg: ''
    });
    const [showType, setShowType] = useState('list');
    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState();
    const [index, setIndex] = useState();
    const [reload, setReload] = useState(false);

    const setEditDriver = (driver, index) => {
        setDriver(driver);
        setIndex(index);
        setShowType('edit');
    }
    const onUpdateDriver = (driver, index) => {
        const driverData = { ...driver };
        const userData = { ...driver.user };
        delete driverData.user;
        axios.patch(apis.BASE_URL + apis.DRIVER_ACCOUNT_UPDATE + driverData.id, driverData)
            .then(() => {
                axios.patch(apis.BASE_URL + apis.USER_ACCOUNT_UPDATE + userData.id, userData)
                    .then(() => {
                        const _drivers = [...drivers];
                        _drivers.splice(index, 1, driver);
                        setDrivers(_drivers);
                        setShowType('list');
                        setAlert({ type: 'success', open: true, msg: 'Update successfully.' });
                    })
                    .catch(() => {
                        setShowType('list');
                        setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' });
                    })
            })
            .catch(() => {
                setShowType('list');
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' });
            })
    }
    const onDeleteDriver = (driverID, index) => {
        axios.delete(apis.BASE_URL + apis.DRIVER_ACCOUNT_DELETE + driverID)
            .then(() => {
                const _drivers = [...drivers];
                _drivers.splice(index, 1);
                setDrivers(_drivers);
                setAlert({ type: 'success', open: true, msg: 'Delete successfully.' });
            })
            .catch(() => {
                setAlert({ type: 'error', open: true, msg: 'Delete failed, please try again.' });
            })
    }
    const onShowOrders = driver => {
        setDriver(driver);
        setShowType('driverOrders')
    }
    const onUpdateCommisson = commission => {
        axios.patch(apis.BASE_URL + apis.DRIVER_COMMISSIONS_UPDATE + commission)
            .then(res => {
                setAlert({ type: 'success', open: true, msg: 'Update successfully.' });
                setReload(!reload);
            })
            .catch(() => {
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' });
            })
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.DRIVER_ACCOUNTS)
            .then(res => { setDrivers(res.data) })
    }, [reload])

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            {showType === 'list' &&
                <DriverList
                    data={drivers}
                    onEdit={setEditDriver}
                    onDelete={onDeleteDriver}
                    onOrder={onShowOrders}
                    onUpdateCommisson={onUpdateCommisson}
                    onBack={onBack}
                />
            }
            {showType === 'edit' &&
                <DriverEdit
                    driver={driver}
                    index={index}
                    onBack={() => setShowType('list')}
                    onUpdate={onUpdateDriver}
                />
            }
            {showType === 'driverOrders' &&
                <DriverOrders
                    onBack={() => setShowType('list')}
                    driver={driver}
                />
            }
        </>
    )
}
AccountDrivers.propTypes = {
    onBack: PropTypes.func
}
AccountDrivers.defaultProps = {
    onBack: () => { }
}

export default AccountDrivers;
import React, { useState, useEffect } from 'react';
import './StoreInfor.scss'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { getUser } from 'src/utils/localStorageService'
import { Badge, Button } from 'src/components';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { baseUrl } from 'src/urlConfig';

const StoreInfor = (props) => {

    const { t } = useTranslation()
    const [storeInfor, setStoreInfor] = useState({
        id: '',
        restaurant_image: '',
        name: '',
        estimated_delivery: '',
        restaurant_rating: '',
        is_active: '',
        type: '',
        hotline: '',
        commission: ''
    })


    useEffect(() => {
        axios.get(apis.BASE_URL + apis.RESTAURANT + getUser().id)
            .then((res) => {
                setStoreInfor(res.data[0].data)
                localStorage.setItem("restaurant", res.data[0].data && res.data[0].data.id)
            })
    }, [])

    const loadEditStore = (load) => {
        props.callBack(load)
    }

    const renderRating = () => {
        if (storeInfor && storeInfor.restaurant_rating) {
            var arr = []
            for (var i = 1; i <= storeInfor.restaurant_rating; i++) {
                arr.push(<img alt='' src="/images/star.png" key={i} style={{ width: '4%', height: 'auto' }} />)
            }
            return arr
        }
    }

    const renderLabel = () => {
        if (storeInfor && storeInfor.is_active) {
            return <Badge type='active' content={t('opening')} />
        }
        return <Badge type='block' content={t('closing')} />
    }

    return (
        <div className="store-infor">
            <img src={apis.BASE_URL + storeInfor.restaurant_image} alt='restaurant_image' />
            <div className="infor">
                <div className="des">
                    <span className="name">{(storeInfor && storeInfor.name) || ''}</span>
                    <span className="star">{renderRating()}</span>
                    <span className="hotline"><FontAwesomeIcon icon={faPhone} />{(storeInfor && storeInfor.hotline) || ''}</span>
                    <span className="label">{renderLabel()}</span>
                </div>
                <Button title={t('view')} onClick={() => loadEditStore('edit-store')} />
                {/* <button onClick={() => loadEditStore('edit-store')}>View detail</button> */}
            </div>
        </div>
    );
}

export default StoreInfor;
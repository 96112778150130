import React, { useRef, useState } from 'react';
import './Card.scss'
import PropTypes from 'prop-types'

const urlImgArrowRight = '/images/arrow-right.png';
const urlImgArrowTop = '/images/arrow-top.png';


const Card=(props)=> {

    const [showContent,setShowContent] = useState(false)
    const [urlImg,setUrlImg] = useState(urlImgArrowRight)
    const [display,setDisplay] = useState('none')
    const [height,setHeight] = useState(0)
    const content = useRef()

    const change=()=>{
        setShowContent(!showContent)
        if(urlImg === urlImgArrowRight){
            setUrlImg(urlImgArrowTop)
            setDisplay('block')
            setHeight(content.current.offsetHeight)
        }
        else{
            setUrlImg(urlImgArrowRight)
            setDisplay('none')
            setHeight(0)
        }
    }

    if(props.type === 'arrow')
        return (
            <div className="card" >
                <div className="card-header" onClick={change}>
                    <p style={{paddingTop:'4px'}}>{props.title}</p>
                    <img style={{marginLeft: '70%'}} alt='img' src={urlImg} onClick={change} />
                </div>
                <div className="card-body"  style={{ height:height+'px'}}>
                    <div className="content" ref={content}>
                        {props.children}
                    </div>
                    
                </div>
            </div>
        );
    else   
        return (
            <div className="card">
                <div className="card-header" onClick={change}>
                    <h2>{props.title}</h2>
                    <span onClick={change}>Update</span>
                </div>
                <div className="card-body" style={{display:display}}>
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
        );
}

Card.propTypes = {
    type:PropTypes.oneOf(['arrow', 'text']),
    title: PropTypes.string
}
Card.defaultProps = {
    type: 'arrow',
    title: 'Title'
}

export default Card;
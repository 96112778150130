import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DropContent } from 'src/components'
import styles from './Select.module.scss'

const Select = props => {

    const {
        options, value, onChange,
        classNameSelected, className,
        classNameOption
    } = props;

    const selectRef = useRef();
    const [open, setOpen] = useState(false);

    const onChangeSelect = option => {
        onChange(option);
        setOpen(false);
    }

    useEffect(() => {
        const clickOutside = event => {
            if (selectRef.current && !selectRef.current.contains(event.target) && open) {
                setOpen(false)
            }
        }
        document.addEventListener('click', clickOutside)
        return () => document.removeEventListener('click', clickOutside)
    }, [open])

    return (
        <div ref={selectRef} className={`${styles.select} ${className}`}>
            <p
                className={`${styles.selected} ${classNameSelected}`}
                onClick={() => setOpen(!open)}>
                {value}
            </p>
            <DropContent style={{ position: 'absolute', zIndex: 99, width: '100%' }}
                className={styles.options} open={open}>
                {options.map((option, index) => (
                    <p key={index}
                        className={`${styles.option} ${classNameOption}`}
                        onClick={() => onChangeSelect(option)}>
                        {option}
                    </p>
                ))}
            </DropContent>
        </div>
    )
}

Select.propTypes = {
    options: PropTypes.arrayOf(String),
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    classNameSelected: PropTypes.string,
    classNameOption: PropTypes.string
}
Select.defaultProps = {
    options: [],
    onChange: () => { },
    className: '',
    classNameSelected: '',
    classNameOption: ''
}

export default Select;
import React from 'react'
import PropTypes from 'prop-types'

import styles from './Radio.module.scss'

const Radio = props => {

    const { radio, radio_check, radio_label } = styles;

    const { label, size, color, checked, onSelect, style } = props;

    return (
        <span className={radio} onClick={onSelect} style={style}>
            <input type='radio' checked={checked} readOnly />
            <span className={radio_check} style={{
                width: `${size}px`,
                height: `${size}px`,
                border: checked ? 'none' : '2px solid black',
                backgroundColor: checked ? color : '#ffffff'
            }}></span>
            <label className={radio_label}>{label}</label>
        </span>
    )
}

Radio.propTypes = {
    label: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onSelect: PropTypes.func,
    style: PropTypes.object
}

Radio.defaultProps = {
    size: 15,
    color: '#333333',
    name: '',
    checked: false,
    onSelect: () => { }
}

export default Radio;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Back, Button } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { useTranslation } from 'react-i18next'

const UserAdd = props => {

    const { onBack } = props;
    const { t } = useTranslation();

    const [account, setAccount] = useState({
        "mobile": "",
        "email": "",
        "first_name": "",
        "last_name": "",
        "facebook_id": "",
        "google_id": "",
        "password": ""
    })
    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })
    const [confirmPassword, setConfirmPassword] = useState('')

    const [checkPasswordMatch, setCheckPasswordMatch] = useState(false)

    const changeAccount = (event) => {
        const {name, value} = event.target;
        if(name==='password'){
            if(value!==confirmPassword) setCheckPasswordMatch(false)
        }
        setAccount({ ...account, [event.target.name]: event.target.value })
    }

    const btnAddClick = () => {
        axios.post(apis.BASE_URL + apis.USER_ACCOUNT_NEW, account)
            .then(res => {
                const profile = {
                    photo: null,
                    account: res.data[0].data.id
                }
                axios.post(apis.BASE_URL + apis.USER_PROFILE_NEW, profile)
                    .then(() => {
                        setAlert({ ...alert, type: 'success', open: true, msg: 'Successfully' })
                        setInterval(onBack, 3000);
                    })
                    .catch(() => {
                        setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' });
                    })

            })
            .catch(error => {
                setAlert({ ...alert, type: 'error', open: true, msg: 'The mobile have no exist user or email is existed.' })
            });
    }

    const confirm = (event) => {
        if (event.target.value === account.password && account.password.length >= 8) {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(true)
        }
        else {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(false)
        }
    }

    const renderTextCheck = () => {
        if (checkPasswordMatch) {
            return <span style={{ color: 'green' }}>{t('correct')}</span>
        }
        else {
            return <span style={{ color: 'red' }}>{t('noCorrect')}</span>
        }
    }

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('firstName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='first_name'
                        value={account.first_name || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('lastName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='last_name'
                        value={account.last_name || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='mobile'
                        value={account.mobile || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={account.email || ''}
                        onChange={changeAccount}
                        required
                        type="email"
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('password')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type="password"
                        name='password'
                        value={account.password || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <span style={{ color: '#e82d86', fontSize: '12px', marginBottom: '0.8rem' }}>{t('invalidPassword')}</span>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('confirmPassword')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type="password"
                        name='confirmPassword'
                        value={confirmPassword || ''}
                        onChange={confirm}
                        required
                        style={{ marginRight: '1%' }}
                    />
                    {renderTextCheck()}
                </div>
                <div className='edit_form-button'>
                    <Button
                        title={t('create')}
                        onClick={btnAddClick}
                        disabled={!checkPasswordMatch}
                    />
                </div>
            </div>
        </>
    )
}

UserAdd.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func
}
UserAdd.defaultProps = {
    onBack: () => { },
    onAdd: () => { }
}

export default UserAdd;
const dev = 'https://admin.webiliappdemo.com';
const devAPI = 'https://api.webiliappdemo.com';
const prod = 'https://admin.webiliappdemo.com';
const prodAPI = 'https://api.webiliappdemo.com';

export const baseUrl = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return {
                baseurl: dev,
                apiUrl: devAPI
            };
        case 'production':
            return {
                baseurl: prod,
                apiUrl: prodAPI
            };
        default:
            return {
                baseurl: dev,
                apiUrl: devAPI
            };
    }
}

import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './DropContent.module.scss'

const DropContent = props => {

    const { open, className, style } = props;

    const contentRef = useRef();
    const [height, setHeight] = useState();

    useEffect(() => {
        open && contentRef.current && setHeight(contentRef.current.offsetHeight)
    }, [open])

    return (
        <div className={styles.dropContent} style={{ ...style, maxHeight: open ? `${height}px` : '0px' }}>
            <div ref={contentRef} className={className}>
                {props.children}
            </div>
        </div>
    )
}

DropContent.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object
}

DropContent.defaultProps = {
    open: false,
    className: '',
    style: {}
}

export default DropContent;
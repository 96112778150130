import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropContent, Button, Back, ProgressBar } from 'src/components'
import { useTranslation } from 'react-i18next'

const User = props => {

    const { user, open, onClick, onAssign, onUpdate, onDelete } = props;
    const { t } = useTranslation();

    return (
        <div className='account_list'>
            <p className='account_list-item' onClick={onClick}>
                <span className='user_list-title'>{user.first_name || ''} {user.last_name || ''}</span>
                <span className='user_list-title'>{user.address || ''}</span>
                <span className='user_list-title'>{user.mobile || ''}</span>
            </p>
            <DropContent open={open} className='account_list-detail'>
                <div className='account_list--fields'>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('id')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.id || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('firstName')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.first_name || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('lastName')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.last_name || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('phoneNumber')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.mobile}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('email')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.email}</p>
                    </div>
                </div>
                <div className='account_list--button'>
                    <Button title={t('assignToDriver')} onClick={onAssign} />
                    <Button title={t('update')} onClick={onUpdate} style={{ marginLeft: '8px' }} />
                    <Button title={t('delete')} onClick={onDelete} style={{ marginLeft: '8px' }} />
                </div>
            </DropContent>
        </div>
    )
}

const UserList = props => {

    const { data, onNew, onEdit, onAssign, onDelete, onBack } = props;
    const { t } = useTranslation();

    const [pos, setPos] = useState(null);

    const onChangeIndex = index => {
        if (pos === index) {
            setPos(null)
        } else {
            setPos(index)
        }
    }
    const onDeleteUser = (userID, index) => {
        setPos(null);
        onDelete(userID, index);
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <Back title='Back' onBack={onBack} style={{ marginBottom: '0' }} />
                <Button title={t('create')} onClick={onNew} />
            </div>
            {data && data.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {data && data.map((user, index) => (
                <User
                    key={user.id}
                    open={pos === index ? true : false}
                    onClick={() => onChangeIndex(index)}
                    user={user}
                    onAssign={() => onAssign(user, index)}
                    onUpdate={() => onEdit(user, index)}
                    onDelete={() => onDeleteUser(user.id, index)}
                />
            ))}
        </>
    )
}

UserList.propTypes = {
    data: PropTypes.array,
    onNew: PropTypes.func,
    onEdit: PropTypes.func,
    onAssign: PropTypes.func,
    onBack: PropTypes.func,
    onDelete: PropTypes.func
}

export default UserList;
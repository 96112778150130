import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const UserSelected = props => {

    const { user, onClick } = props;
    const { t } = useTranslation();

    return (
        <div onClick={onClick} className='chatBox_header-user'>
            <>
                {user ? (
                    <div className='user_to_chated'>
                        <p className='user_to_chat-avatar'>
                            {`${user.first_name[0]}${user.last_name[0]}`.toUpperCase()}
                        </p>
                        <p className='user_to_chat-name'>
                            {user.first_name} {user.last_name}
                        </p>
                    </div>
                ) : (
                        <p className='no_chat'>{t('textChat')}</p>
                    )}
            </>
        </div>
    )
}

UserSelected.propTypes = {
    user: PropTypes.object,
    onClick: PropTypes.func
}

export default UserSelected;
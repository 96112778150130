import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import Axios from 'axios';
import { useTranslation } from 'react-i18next'
import apis from 'src/apiConfig'

const AddAccount = (props) => {

    const { t } = useTranslation();
    const { onBack } = props;

    const [account, setAccount] = useState({
        "mobile": "",
        "email": "",
        "first_name": "",
        "last_name": "",
        "facebook_id": "",
        "google_id": "",
        "password": ""
    })
    const [confirmPassword, setConfirmPassword] = useState('')

    const [checkPasswordMatch, setCheckPasswordMatch] = useState(false)

    const changeAccount = (event) => {
        setAccount({ ...account, [event.target.name]: event.target.value })
    }

    const btnAddClick = () => {
        console.log(account)
        Axios.post(apis.BASE_URL + apis.USER_ACCOUNT_NEW, account, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
        })
            .then(res => {
                console.log(res.data)
                alert("Create Successfully!")
                onBack()
            })
            .catch(error => {
                alert("error")
                console.log(error)
            });
    }

    const confirm = (event) => {
        if (event.target.value === account.password && account.password.length >= 8) {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(true)
        }
        else {
            setConfirmPassword(event.target.value)
            setCheckPasswordMatch(false)
        }
    }

    const renderTextCheck = () => {
        if (checkPasswordMatch) {
            return <span style={{ color: 'green' }}>Correct</span>
        }
        else {
            return <span style={{ color: 'red' }}>Not correct</span>
        }
    }

    return (
        <div style={{ paddingLeft: '2%' }}>
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('firstName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='first_name'
                        value={account.first_name || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('lastName')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='last_name'
                        value={account.last_name || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='mobile'
                        value={account.mobile || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={account.email || ''}
                        onChange={changeAccount}
                        required
                        type="email"
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('password')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type="password"
                        name='password'
                        value={account.password || ''}
                        onChange={changeAccount}
                        required
                    />
                </div>
                <span style={{ color: '#e82d86', fontSize: '12px', marginBottom: '0.8rem' }}>Password must have at least 8 characters.</span>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('confirmPassword')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type="password"
                        name='confirmPassword'
                        value={confirmPassword || ''}
                        onChange={confirm}
                        required
                        style={{ marginRight: '1%' }}
                    />
                    {renderTextCheck()}
                </div>
                <div className='edit_form-button'>
                    <Button
                        title={t('create')}
                        onClick={btnAddClick}
                        disabled={!checkPasswordMatch}
                    />
                </div>
            </div>
        </div>
    )
}

AddAccount.propTypes = {
    onBack: PropTypes.func
}
AddAccount.defaultProps = {
    onBack: () => { }
}

export default AddAccount;
import React, { useEffect, useState } from 'react';
import './EditStore.scss'
import PropTypes from 'prop-types'
import { Alert, Back, Button, Radio } from 'src/components';
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { useTranslation } from 'react-i18next'

const EditStore = (props) => {

    const { t } = useTranslation();
    const { onBack } = props
    const [alert, setAlert] = useState({
        type: 'success',
        msg: '',
        open: false
    })
    const [haveChangeImg, setHaveChangeImg] = useState(false)
    const [store, setStore] = useState({
        name: '',
        is_active: '',
        restaurant_rating: '',
        restaurant_image: '',
        hotline: '',
        commission: '',
        type: ''
    })
    const [urlImage, setUrlImage] = useState("");

    useEffect(() => {
        setUrlImage(props.restaurant.restaurant_image)
        setStore(props.restaurant)
    }, [])

    const onChangeFile = event => {
        setHaveChangeImg(true)
        setUrlImage(URL.createObjectURL(event.target.files[0]))
        setStore({ ...store, restaurant_image: event.target.files[0] })
    }
    const onChangeStore = event => {
        const { name, value } = event.target;
        setStore({ ...store, [name]: value })
    }
    const btnUpdateClick = () => {
        const form = new FormData()
        form.append("name", store.name)
        form.append("is_active", store.is_active)
        form.append("restaurant_rating", store.restaurant_rating)
        haveChangeImg === true && form.append("restaurant_image", store.restaurant_image)
        form.append("hotline", store.hotline)
        form.append("commission", store.commission)
        form.append("type", store.type)


        axios.patch(apis.BASE_URL + apis.RESTAURANT_UPDATE + store.id, form)
            .then(res => {
                setAlert({ ...alert, type: 'success', msg: 'Update restaurant successfully!', open: true })
            })
            .catch(err => {
                setAlert({ ...alert, type: 'error', msg: 'Update restaurant failed!', open: true })
            })
    }
    return (
        <>
            <Alert type={alert.type} msg={alert.msg} open={alert.open} onClose={() => {
                onBack()
                setAlert({ type: 'success', msg: '', open: false })
            }} />
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <div className="mad_service" style={{ width: '100%', height: 'auto' }}>
                        <div className='image'>
                            <p>{t('image')}</p>
                            <div style={{ display: 'flex' }}>
                                <label htmlFor='commission_image'>
                                    <span style={{ display: !urlImage ? 'block' : 'none' }}>{t('browse')}</span>
                                    <img alt='' src={urlImage} width='100%' height='auto' style={{ display: urlImage ? 'inline' : 'none' }} />
                                </label>
                                <input
                                    onChange={onChangeFile}
                                    id='commission_image'
                                    type='file'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.name')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='name'
                        value={store.name || ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('newRestaurant.hotline')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='text'
                        name='hotline'
                        value={store.hotline || ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('rating')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='number'
                        min={1}
                        max={5}
                        name='restaurant_rating'
                        value={store.restaurant_rating || ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('orderTable.commission')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='commission'
                        value={store.commission !== '' ? store.commission : ''}
                        onChange={onChangeStore}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('status')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <div className='edit_form-radioGroup'>
                        <Radio
                            label={t('active')}
                            color='#e82d86'
                            checked={store.is_active === true}
                            onSelect={() => setStore({ ...store, is_active: true })}
                        />
                        <Radio
                            label={t('inactive')}
                            color='#e82d86'
                            checked={store.is_active === false}
                            onSelect={() => setStore({ ...store, is_active: false })}
                        />
                    </div>
                </div>
                <div className='edit_form-button'>
                    <Button
                        title={t('update')}
                        onClick={btnUpdateClick}
                    />
                </div>
            </div>
        </>
    );
}

EditStore.propTypes = {
    onBack: PropTypes.func
}
EditStore.defaultProps = {
    onBack: () => { }
}

export default EditStore;
import React from 'react'
import PropTypes from 'prop-types'
import { Button, ProgressBar } from 'src/components'
import PromotionCoupon from './PromotionCoupon'
import { useTranslation } from 'react-i18next'

const PromotionList = props => {

    const { coupons, add, update, remove } = props;
    const { t } = useTranslation();

    return (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button title={t('create')} onClick={add} />
            </div>
            {coupons.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {coupons.length > 0 && coupons.map((coupon, index) => (
                <div key={coupon.id} className='coupon_box'>
                    <PromotionCoupon
                        coupon={coupon}
                        update={() => update(coupon)}
                        remove={() => remove(coupon.id, index)}
                    />
                </div>
            ))}
        </>
    )
}

PromotionList.propTypes = {
    add: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func
}

export default PromotionList;
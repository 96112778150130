import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button, ProgressBar } from 'src/components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { getUser } from 'src/utils/localStorageService';

const customStyles = {
    content: {
        textAlign: 'center',
        width: '40%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



const ModalAccount = (props) => {

    const { show, onClose, onBack } = props;
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [passwordIncorrect,setPasswordIncorrect] = useState(false)

    const [input, setInput] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const [validate, setValidate] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    })

    // check validate

    const changeInputCurrentPw = (event) => {
        setInput({ ...input, currentPassword: event.target.value })
        if (event.target.value.length > 0) {
            setValidate({ ...validate, currentPassword: true })
        }
        else {
            setValidate({ ...validate, currentPassword: false })
        }
    }
    const changeInputNewPw = (event) => {
        const value = event.target.value
        setInput({ ...input, newPassword: value })

        if (value.length >= 8) {
            if (value === input.confirmPassword) setValidate({ ...validate, confirmPassword: true, newPassword: true })
            else setValidate({ ...validate, confirmPassword: false, newPassword: true })
        }
        else {
            if (value === input.confirmPassword) setValidate({ ...validate, confirmPassword: true, newPassword: false })
            else setValidate({ ...validate, confirmPassword: false, newPassword: false })
        }

    }
    const changeInputConfirmPw = (event) => {
        setInput({ ...input, confirmPassword: event.target.value })
        if (event.target.value === input.newPassword) {
            setValidate({ ...validate, confirmPassword: true })
        }
        else {
            setValidate({ ...validate, confirmPassword: false })
        }
    }


    //render announce when check validate password 
    const renderTextCheckConfirmPw = () => {
        if (validate.confirmPassword === true) {
            return <span style={{ color: 'green' }}>
                <FontAwesomeIcon icon={faCheck} />
            </span>
        }
        else {
            return <span style={{ color: 'red' }}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        }
    }
    const renderTextCheckNewPw = () => {
        if (validate.newPassword === true) {
            return <span style={{ color: 'green' }}>
                <FontAwesomeIcon icon={faCheck} />
            </span>
        }
        else {
            return <span style={{ color: 'red' }}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        }
    }

    const turnButtonUpdate = () => {
        if (validate.confirmPassword === true && validate.currentPassword === true && validate.newPassword === true) return false;
        else return true;
    }

    const btnUpdateClick = () => {
        setLoading(true)
        const payload = {
            old_password: input.currentPassword,
            new_password: input.newPassword
        }
        axios.put(apis.BASE_URL + apis.CHANGE_PASSWORD + getUser().id + '/', payload)
            .then(res => {
                setPasswordIncorrect(false);
                onBack(true)
                setLoading(false)
            })
            .catch(error => {
                setPasswordIncorrect(true)
                setLoading(false)
            });
    }



    function afterOpenModal() {
    }

    function closeModal() {
        setInput({
            confirmPassword:'',
            currentPassword:'',
            newPassword:''
        })
        setLoading(false);
        setPasswordIncorrect(false)
        setValidate({
            confirmPassword:false,
            currentPassword:false,
            newPassword:false
        })
        onClose()
    }
    return (
        <div>
            <Modal
                isOpen={show}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='edit_form-field' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <p className='edit_form-key'>
                                <span style={{ fontSize: '1rem', fontWeight: '600' }}>{t('currentPassword')}</span>
                            </p>
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="password"
                                    name='currentPassword'
                                    value={input.currentPassword || ''}
                                    onChange={changeInputCurrentPw}
                                    required
                                />
                            </div>
                            {
                                passwordIncorrect===true 
                                &&
                                <span style={{ color: '#e82d86', fontSize: '12px', marginBottom: '0.8rem' }}>Current password incorrect, please try again.</span>
                            }
                            
                        </div>
                        <br/>
                        <div className='edit_form-field' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <p className='edit_form-key'>
                                <span style={{ fontSize: '1rem', fontWeight: '600' }}>{t('password')}</span>
                            </p>
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="password"
                                    name='newPassword'
                                    value={input.newPassword || ''}
                                    onChange={changeInputNewPw}
                                    required
                                />
                                {renderTextCheckNewPw()}
                            </div>
                        </div>
                        <span style={{ color: '#3a414185', fontSize: '12px', marginBottom: '0.8rem' }}>Password must have at least 8 characters.</span>
                        <div className='edit_form-field' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <p className='edit_form-key'>
                                <span style={{ fontSize: '1rem', fontWeight: '600' }}>{t('confirmPassword')}</span>
                            </p>
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="password"
                                    name='confirmPassword'
                                    value={input.confirmPassword || ''}
                                    onChange={changeInputConfirmPw}
                                    required
                                />
                                {renderTextCheckConfirmPw()}
                            </div>
                        </div>
                    </div>
                </div>


                {
                    loading === true
                    &&
                    <div colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ProgressBar />
                        <span style={{ fontWeight: '400' }}>Loading...</span>
                    </div>
                }
                <Button title='Update' onClick={btnUpdateClick} color='#e82d86' disabled={turnButtonUpdate()} />
                <Button title='Close' onClick={closeModal} color='#8c8c8c' />
            </Modal>
        </div>
    );
}

ModalAccount.propTypes = {
    driver: PropTypes.object,
    show: PropTypes.bool,
    onClose: PropTypes.func
}
ModalAccount.defaultProps = {
    driver: {
        latitude: '0',
        longitude: '0'
    },
    show: false,
    onClose: () => { }
}
export default ModalAccount;
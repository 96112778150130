const initState = {
    ln: 'English'
}

const languageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            return {
                ...state,
                ln: action.language
            }
        default:
            return state
    }
}

export default languageReducer;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './List.module.scss'

const List = props => {

    const { item, item_active } = styles;

    const { dataList, classNameList, classNameItem, onClickItem } = props;

    const [itemSelected, setItemSelected] = useState(0);

    const onHandleClick = index => {
        setItemSelected(index);
        onClickItem(index);
    }

    return (
        <div className={classNameList}>
            {dataList.map((data, index) => (
                <p
                    key={index}
                    className={`${item} ${classNameItem} ${itemSelected === index ? `${item_active}` : ''}`}
                    onClick={() => onHandleClick(index)}>
                    {data}
                </p>
            ))}
        </div>
    )
}

List.propTypes = {
    dataList: PropTypes.array,
    classNameList: PropTypes.string,
    classNameItem: PropTypes.string,
    onClickItem: PropTypes.func
}
List.defaultProps = {
    classNameList: '',
    classNameItem: '',
    onClickItem: () => { }
}

export default List
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, ProgressBar } from 'src/components'
import PropTypes from 'prop-types'

const customStyles = {
    content: {
        textAlign: 'center',
        width: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const ModalLocation = (props) => {

    const { driver, show, onClose } = props;
    const [loading, setLoading] = useState(true);

    function afterOpenModal() {
    }

    function closeModal() {
        onClose()
        setLoading(true)
    }

    const loadComplete = () => {
        setLoading(false)
    }
    return (
        <div>
            <Modal
                isOpen={show}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">

                {
                    driver !== null
                    &&
                    <iframe
                        title='modal_location'
                        onLoad={loadComplete}
                        width="100%"
                        height="300"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src={'https://maps.google.com/maps?q=' + driver.latitude + ',' + driver.longitude + '&t=&z=15&ie=UTF8&iwloc=&output=embed'}
                    />
                }
                {
                    loading === true
                    &&
                    <div colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ProgressBar />
                        <span style={{ fontWeight: '400' }}>Loading...</span>
                    </div>
                }
                <Button title='Close' onClick={closeModal} />
            </Modal>
        </div>
    );
}

ModalLocation.propTypes = {
    driver: PropTypes.object,
    show: PropTypes.bool,
    onClose: PropTypes.func
}
ModalLocation.defaultProps = {
    driver: {
        latitude: '0',
        longitude: '0'
    },
    show: false,
    onClose: () => { }
}
export default ModalLocation;
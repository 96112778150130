import React from 'react'
import './MiniLayout.scss'

const MiniLayout = props => {
    return (
        <div className='minilayout'>
            {props.children}
        </div>
    )
}

export default MiniLayout;
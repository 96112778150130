import React, { useState } from 'react'
import {
    ServiceMenu,
    ServiceOrder,
    InvoiceList,
    ServiceComInvoice
} from './components'

const ServiceManagement = props => {

    const [serviceType, setServiceType] = useState('menu');
    const [invoice, setInvoice] = useState();

    const showInvoiceDetail = invoice => {
        setInvoice(invoice);
        setServiceType('invoice-detail');
    }

    return (
        <>
            {serviceType === 'menu' && <ServiceMenu onChange={type => setServiceType(type)} />}
            {serviceType === 'pending-order' && <ServiceOrder onBack={() => setServiceType('menu')} />}
            {serviceType === 'invoices' &&
                <InvoiceList
                    onBack={() => setServiceType('menu')}
                    onClick={showInvoiceDetail}
                />
            }
            {serviceType === 'invoice-detail' &&
                <ServiceComInvoice
                    invoice={invoice}
                    onBack={() => setServiceType('invoices')}
                />
            }
        </>
    )
}

export default ServiceManagement;
import React from 'react'
import PropTypes from 'prop-types'
import { Back } from 'src/components'

const AdminList = props => {

    const { data, onBack } = props;

    return (
        <>
            <div className='operator_list-action'>
                <Back onBack={onBack} />
            </div>
            {data.map((admin, index) => (
                <p className='admin_list-item' key={index}>
                    <span className='admin_list-text'>{admin.username || ''}</span>
                    <span className='admin_list-text'>{admin.phoneNumber || ''}</span>
                </p>
            ))}
        </>
    )
}

AdminList.propTypes = {
    data: PropTypes.arrayOf(Object),
    onBack: PropTypes.func,
}
AdminList.defaultProps = {
    data: [],
    onBack: () => { },
}

export default AdminList;
import React, { useState, useEffect } from 'react';
import './EditItem.scss'
import Switch from 'src/components/Switch';
import axios from 'src/utils/axios';
import { Back, Badge, Button } from 'src/components';
import { useTranslation } from 'react-i18next'
import apis from 'src/apiConfig'

const EditItem = (props) => {
    const { onSuccess } = props

    const { t } = useTranslation();
    const [editing, setEditing] = useState(false)
    const [classInput, setClassInput] = useState('disable')
    const [display, setDisplay] = useState('none')
    const [item, setItem] = useState({
        id: '',
        item_image: '',
        item_name: '',
        type: -1,
        unit_price: '',
        ingredients: '',
        food: '',
        restaurants: '',
        availability: '',
        is_toping: ''
    })
    const [haveChangImg, setHaveChangImg] = useState(false)

    const [imgReview, setImgReview] = useState(item.item_image)

    useEffect(() => {
        setItem(props.item)
        setImgReview(props.item.item_image)
    }, [props.item])

    const btnBackClick = () => {
        props.callBack('store-menu')
    }
    const btnAddClick = () => {
        props.callBack('new-item')
    }

    const switchCallBack = (status) => {
        setEditing(status)
        classInput === 'disable' && setClassInput('enable')
        classInput === 'enable' && setClassInput('disable')
        status === true && setDisplay('block')
        status === false && setDisplay('none')
    }

    const changeImg = (event) => {
        setHaveChangImg(true)
        setItem({ ...item, 'item_image': event.target.files[0] })
        setImgReview(URL.createObjectURL(event.target.files[0]))
    }

    const btnUpdateClick = () => {
        var form = new FormData()
        form.append("restaurants", localStorage.getItem("restaurant"))
        form.append("item_name", item.item_name)
        form.append("unit_price", item.unit_price)
        form.append("food", item.food)
        form.append("type", item.type)
        form.append("availability", item.availability)
        form.append("is_toping", item.is_toping)
        if (haveChangImg) {
            form.append("item_image", item.item_image)
        }


        var urlUpdate = apis.BASE_URL + '/menuItem/patch?id=' + item.id
        axios.patch(urlUpdate, form)
            .then(res => {
                onSuccess()
            })
            .catch(err => {
                alert("error")
            });
    }

    const change = (event) => {
        setItem({ ...item, [event.target.name]: event.target.value })
    }

    const changeCheckbox = (event) => {
        setItem({ ...item, [event.target.name]: event.target.checked })
    }

    const renderBadge = (availability) => {
        if (availability) {
            return <Badge type='action' content={t('available')} />
        }
        return <Badge type='block' content={t('unavailable')} />
    }

    const renderListTopping = () => {
        if (item.toping) return item.toping.map((topping, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                        <img alt='topping_image' src={apis.BASE_URL + topping.item_image} />
                    </td>
                    <th>{topping.item_name}</th>
                    <td>${topping.unit_price}</td>
                    <td className="label">
                        {
                            renderBadge(topping.availability)
                        }
                    </td>
                </tr>
            )
        })
    }
    return (
        <div className="edit-item">
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button>
                <button onClick={btnAddClick}>
                    <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '1%' }} />
                    Add
                </button> */}
                <Back onBack={btnBackClick} />
                <Button title={t('create')} onClick={btnAddClick} />
            </div>
            <div className="store">
                <div className="head">
                    <span>{t('item')}</span>
                    <div className="right">
                        <span>{t('edit')}</span>
                        <Switch callBack={switchCallBack} />
                    </div>

                </div>
                <div className="body">
                    <div className="picture">
                        <img alt='topping_image' src={apis.BASE_URL + imgReview} />
                        <input type="file" style={{ display: display }} onChange={changeImg} />
                    </div>
                    <div className="infor">
                        <div className="form">
                            <span>{t('name')}: </span>
                            <input className={classInput} type="text" value={item.item_name} disabled={!editing} name="item_name" onChange={change} />
                        </div>
                        <div className="form">
                            <span>{t('type')}: </span>
                            <select className={classInput} name='type' onChange={change} disabled={!editing} value={item.type}>
                                <option value={1} >Starter</option>
                                <option value={2} >Meal</option>
                                <option value={3} >Dessert</option>
                            </select>
                        </div>
                        <div className="form">
                            <span>{t('price')}: </span>
                            <input className={classInput} type="number" value={item.unit_price} disabled={!editing} name="unit_price" onChange={change} />
                        </div>
                        <div className="form">
                            <span>{t('ingredients')}: </span>
                            <textarea name="ingredients" value={item.ingredients?item.ingredients:''} onChange={change} disabled={!editing} className={classInput}></textarea>
                        </div>
                        <div className="form">
                            <span>{t('available')}: </span>
                            <input className={classInput} type="checkbox" checked={item.availability} disabled={!editing} name="availability" onChange={changeCheckbox} />
                        </div>
                        <div className="form">
                            <span>{t('topping')}: </span>
                            <input className={classInput} type="checkbox" checked={item.is_toping} disabled={!editing} name="is_toping" onChange={changeCheckbox} />
                        </div>
                        <div className="list-topping">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t('image')}</th>
                                        <th>{t('name')}</th>
                                        <th>{t('price')}</th>
                                        <th>{t('status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderListTopping()}
                                </tbody>

                            </table>
                        </div>
                        <button style={{ display: display }} onClick={btnUpdateClick}>{t('update')}</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default EditItem;
import React from 'react'
import PropTypes from 'prop-types'
import { Back } from 'src/components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const TransactionStore = props => {

    const { onBack, transaction, showOrderDetail } = props;
    const { t } = useTranslation();

    return (
        <>
            <Back onBack={onBack} style={{ marginBottom: '0' }} />
            <div className='store_transaction'>
                <div className='store_transaction-field'>
                    <span>{t('id')}</span>
                    <input value={transaction.sale_order} />
                    <img onClick={() => showOrderDetail(transaction.sale_order)} src='/images/note.png' alt='order' />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('date')}</span>
                    <input value={moment(transaction.payment_datetime).format('MM/DD/YYYY')} />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('time')}</span>
                    <input value={moment(transaction.payment_datetime).format('LT')} />
                </div>
                <div className='store_transaction-field'>
                    <span>{t('orderTable.paymentMethod')}</span>
                    <div className='store_transaction-pays'>
                        <p style={transaction.payment_method === 1 ? { backgroundColor: '#e82d86', color: '#ffffff' } : null}>Bank Card</p>
                        <p style={transaction.payment_method === 2 ? { backgroundColor: '#e82d86', color: '#ffffff' } : null}>COD</p>
                    </div>
                </div>
                <textarea
                    rows='4'
                    className='store_transaction-desc'
                    placeholder={t('description')}
                />
                <div className='store_transaction-field'>
                    <span>{t('amount')}</span>
                    <input value={transaction.amount} />
                </div>
            </div>
        </>
    )
}

TransactionStore.propTypes = {
    onBack: PropTypes.func,
    transaction: PropTypes.object,
    showOrderDetail: PropTypes.func
}

export default TransactionStore;
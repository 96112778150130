import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button, DropContent, ProgressBar } from 'src/components'
import { useTranslation } from 'react-i18next'

const Operator = props => {

    const { t } = useTranslation();
    const { open, operator, onClick, onUpdate, onDelete } = props;
    const { user } = operator;

    return (
        <div className='account_list'>
            <p className='account_list-item' onClick={onClick}>
                <span className='driver_list-title'>{user.first_name || ''} {user.last_name}</span>
                <span className='driver_list-title'>{user.mobile || ''}</span>
                <span className='driver_list-title'>{t('restaurantId')}: {operator.restaurant_id || ''}</span>
                <span className='driver_list-title'>{t('type')}: {operator.type}</span>
            </p>
            <DropContent open={open} className='account_list-detail'>
                <div className='account_list--fields'>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('id')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{operator.id || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('operatorName')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.first_name || ''} {user.last_name}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('phoneNumber')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.mobile || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('email')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.email || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('restaurantId')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{operator.id || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('restaurantType')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{operator.type || ''}</p>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button title={t('update')} onClick={onUpdate} />
                        <Button title={t('delete')} onClick={onDelete} style={{ marginLeft: '5px' }} />
                    </div>
                </div>
            </DropContent>
        </div>
    )
}

const OperatorList = props => {

    const { data, onBack, onClick, onClickAddUser, onClickAssign, onEdit, onDelete } = props;
    const { t } = useTranslation();

    const [pos, setPos] = useState(null);

    const onChangeIndex = index => {
        if (pos === index) {
            setPos(null)
        } else {
            setPos(index)
        }
    }
    const onDeleteOperator = (operatorID, index) => {
        setPos(null);
        onDelete(operatorID, index)
    }

    return (
        <>
            <div className='operator_list-action'>
                <Back onBack={onBack} style={{ marginBottom: '0' }} />
                <div>
                    <Button title={t('newRestaurant.new')} onClick={onClick} />
                    <Button title={t('newUser')} onClick={onClickAddUser} />
                    <Button title={t('assignUser')} onClick={onClickAssign} />
                </div>
            </div>
            {data.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {data.map((operator, index) => (
                <Operator
                    key={operator.id}
                    open={pos === index ? true : false}
                    onClick={() => onChangeIndex(index)}
                    operator={operator}
                    onUpdate={() => onEdit(operator, index)}
                    onDelete={() => onDeleteOperator(operator, index)}
                />
            ))}
        </>
    )
}

OperatorList.propTypes = {
    data: PropTypes.arrayOf(Object),
    onBack: PropTypes.func,
    onClick: PropTypes.func,
    onClickAddUser: PropTypes.func,
    onClickAssign: PropTypes.func,
    onDelete: PropTypes.func
}
OperatorList.defaultProps = {
    data: [],
    onBack: () => { },
    onClick: () => { },
    onClickAddUser: () => { },
    onClickAssign: () => { },
    onDelete: () => { }
}

export default OperatorList;
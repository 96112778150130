import React from 'react'
import { Header } from './components'
import 'src/styles/MainLayout.scss'

const MainLayout = props => {
    return (
        <div className='layout'>
            <Header />
            <div className='content'>
                {props.children}
            </div>
        </div>
    )
}

export default MainLayout;
import React from 'react';
import './Tabs.scss'
import { getUser } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'
import { List } from 'src/components'

const Tabs = (props) => {

    const { t } = useTranslation();
    const user = getUser();

    const tabsData = [
        t('restaurant'),
        t('branchManagement'),
        t('saleOrderManagement'),
        t('paymentManagement'),
    ]

    user.is_superuser && tabsData.push(`${t('adminManagement')}`);

    return (
        <List dataList={tabsData} onClickItem={props.changeCallBack} />
    );
}

export default Tabs;
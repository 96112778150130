import React from 'react';
import './Badge.scss'

const Badge = (props) => {
    const { type, content } = props
    var color
    switch (type) {
        case 'active':
            color = "rgb(27, 168, 27)"
            break;
        case 'block':
            color = "rgb(252, 49, 49)"
            break;
        case 'cancel':
            color = "#8c8c8c"
            break;
        default:
            color = "rgb(27, 168, 27)"
            break;
    }

    return (
        <span className="badge" style={{ backgroundColor: color }}>
            {content}
        </span>
    );
}

Badge.defaultProps = {
    type: 'active',
    content: ""
}


export default Badge;
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, DropContent } from 'src/components'
import { getUser } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'
import { Input, Alert } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'

const AccountSettings = props => {

    const { t } = useTranslation();
    const userLogged = getUser();
    const history = useHistory();

    const [showInfor, setShowInfor] = useState(false);
    const [changePassword, setChangePassword] = useState({});
    const [alert, setAlert] = useState({});

    const onChangePassword = event => {
        const { name, value } = event.target;
        setChangePassword({ ...changePassword, [name]: value })
    }
    const onUpdatePassword = () => {
        axios.patch(apis.BASE_URL + apis.USER_ACCOUNT_UPDATE + userLogged.id, changePassword)
            .then(res => logout())
            .catch(error => {
                const message = error.response.data.old_password || ['New password has at least 8 characters.'];
                setAlert({ type: 'error', open: true, msg: message[0] })
            })
    }
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('restaurant');
        history.replace('/login');
    }

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({})}
                msg={alert.msg || ''}
            />
            <div className='account_settings'>
                <p onClick={() => setShowInfor(!showInfor)} className='account_settings--title'>
                    <span className='account_settings--infor'>{t('information')}</span>
                    <span style={showInfor ? { display: "none" } : null}
                        className='account_settings--update'>{t('update')}</span>
                </p>
                <DropContent open={showInfor} className='account_settings--content'>
                    <div className='account_settings--field'>
                        <span>{t('id')}</span>
                        <Input
                            disabled
                            fieldClassName='input-field'
                            value={userLogged.id}
                        />
                    </div>
                    <div className='account_settings--field'>
                        <span>{t('username')}</span>
                        <Input
                            disabled
                            fieldClassName='input-field'
                            value={`${userLogged.first_name} ${userLogged.last_name}`}
                        />
                    </div>
                    <div className='account_settings--field'>
                        <span>{t('password')}</span>
                        <Input
                            fieldClassName='input-field'
                            type='password'
                            name='old_password'
                            value={changePassword.old_password || ''}
                            onChange={onChangePassword}
                        />
                    </div>
                    <div className='account_settings--field'>
                        <span>{t('changePassword')}</span>
                        <Input
                            fieldClassName='input-field'
                            type='password'
                            name='new_password'
                            value={changePassword.new_password || ''}
                            onChange={onChangePassword}
                        />
                    </div>
                    <div className='account_settings--field'>
                        <Button title={t('update')} onClick={onUpdatePassword} />
                    </div>
                </DropContent>
            </div>
        </>
    )
}

export default AccountSettings;
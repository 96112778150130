import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import { useTranslation } from 'react-i18next'

const AssignToDriver = props => {

    const { user, onBack, onAssign, index } = props;
    const { t } = useTranslation();

    const [data, setData] = useState({
        user: user.id,
        is_online: false,
        commission: ''
    });

    const onChangeData = event => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    return (
        <>
            <Back onBack={onBack} />
            <div className='edit_title'>
                <span>Client ID</span>
                <span>{user.id}</span>
            </div>
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('orderTable.commission')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='commission'
                        value={data.commission || ''}
                        onChange={onChangeData}
                    />
                </div>
                <div className='edit_form-button'>
                    <Button
                        title='Assign'
                        onClick={() => onAssign(data, index)}
                    />
                </div>
            </div>
        </>
    )
}

AssignToDriver.propTypes = {
    user: PropTypes.object,
    onBack: PropTypes.func,
    onAssign: PropTypes.func,
    index: PropTypes.number
}

export default AssignToDriver;
const firebaseConfig = {
    apiKey: "AIzaSyAl69cfGzZPxnMHp3Q3V4ZK2S9r_45TFvw",
    authDomain: "webili-user.firebaseapp.com",
    databaseURL: "https://webili-user.firebaseio.com",
    projectId: "webili-user",
    storageBucket: "webili-user.appspot.com",
    messagingSenderId: "715270328015",
    appId: "1:715270328015:web:31b65e05d8af11c666f1ba",
    measurementId: "G-NLF1YZJ4D4"
}

export default firebaseConfig;
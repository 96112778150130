import React from 'react'
import PropTypes from 'prop-types'
import styles from './Avatar.module.scss'

const Avatar = props => {

    const {className, style, type, src, size} = props

    return (
        <img
            style={style}
            className={`${type === 'square' ? styles.avatarSquare : styles.avatarCircle} ${className}`}
            src={src}
            alt='avatar'
            width={size + 'px'}
            height={size + 'px'}
        />
    )
}

Avatar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.oneOf(['square', 'circle']),
    src: PropTypes.string,
    size: PropTypes.number
}
Avatar.defaultProps = {
    className: '',
    type: 'square',
    src: '/images/no-picture.png',
    size: 100
}

export default Avatar;
import axios from 'axios'
import apis from 'src/apiConfig'
 
const axiosInstance = (endpoint, method, body) => {
    if(endpoint === null || endpoint === undefined) endpoint = "" 
    return axios({
        headers: { 
                    'Authorization': 'JWT '+localStorage.token
                },
        method,
        url: apis.BASE_URL+endpoint,
        data: body
    })
}

export default axiosInstance;


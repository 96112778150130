import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import { useTranslation } from 'react-i18next'

const AdminAdd = props => {

    const { onBack, onAdd } = props;
    const { t } = useTranslation();

    const [admin, setAdmin] = useState({});

    const onChangeAdmin = event => {
        const { name, value } = event.target;
        setAdmin({ ...admin, [name]: value })
    }

    return (
        <>
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('username')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='username'
                        value={admin.username || ''}
                        onChange={onChangeAdmin}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('password')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        type='password'
                        name='password'
                        value={admin.password || ''}
                        onChange={onChangeAdmin}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('phoneNumber')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='phoneNumber'
                        value={admin.phoneNumber || ''}
                        onChange={onChangeAdmin}
                    />
                </div>
                <div className='edit_form-field'>
                    <p className='edit_form-key'>
                        <span className='edit_form-name'>{t('email')}</span>
                        <span className='edit_form-name'>:</span>
                    </p>
                    <input
                        name='email'
                        value={admin.email || ''}
                        onChange={onChangeAdmin}
                    />
                </div>
                <div className='edit_form-button'>
                    <Button
                        title='Update'
                        disabled={!admin.username || !admin.password || !admin.phoneNumber || !admin.email}
                        onClick={() => onAdd(admin)}
                    />
                </div>
            </div>
        </>
    )
}

AdminAdd.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func
}
AdminAdd.defaultProps = {
    onBack: () => { },
    onAdd: () => { }
}

export default AdminAdd;
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button, Input, Select } from 'src/components'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from 'react-i18next'

const CustomInput = ({ value, onClick }) => {
    return (
        <Input
            value={value}
            onClick={onClick}
        />
    )
}

const PromotionAdd = props => {

    const { coupon, onBack, add, update } = props;
    const { t } = useTranslation();

    const [_coupon, setCoupon] = useState({});
    const [file, setFile] = useState();

    const onChangeCoupon = event => {
        const { name, value } = event.target;
        setCoupon({ ..._coupon, [name]: value })
    }
    const onChangeFile = event => {
        setCoupon({ ...coupon, promo_image: URL.createObjectURL(event.target.files[0]) });
        setFile(event.target.files[0]);
    }
    const disabled = () => {
        const { name_promo, promo_image, promo_code, promo_type, promo_value, start_date, end_date } = _coupon;
        if (!name_promo || !promo_image || !promo_code || !promo_type || !promo_value || !start_date || !end_date) {
            return true
        } else {
            return false
        }
    }
    const submit = () => {
        const formData = new FormData();
        const type = _coupon.promo_type === 'Percentage' ? 1 : 2;
        file && formData.append('promo_image', file);
        formData.append('name_promo', _coupon.name_promo.toUpperCase());
        formData.append('promo_code', _coupon.promo_code);
        formData.append('promo_type', type);
        formData.append('promo_value', _coupon.promo_value);
        formData.append('start_date', _coupon.start_date);
        formData.append('end_date', _coupon.end_date);
        if (coupon) {
            update(formData, coupon.id)
        } else {
            add(formData)
        }
    }

    useEffect(() => {
        if (coupon) {
            setCoupon(coupon)
        }
    }, [coupon])

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Back style={{ marginBottom: '0' }} onBack={onBack} />
                <Button disabled={disabled()} title={coupon ? 'Update' : 'Create'} onClick={submit} />
            </div>
            <div className='promotion_coupon_add'>
                <label className='coupon_image' htmlFor='promo_image'>
                    <input id='promo_image' type='file' accept='image/*' onChange={onChangeFile} style={{ display: 'none' }} />
                    <img src={_coupon.promo_image || '/images/no-image.png'} alt='img' />
                </label>
                <div className='promotion_coupon-infor'>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponName')} </strong>
                        <Input
                            fieldClassName='promotion_coupon-input'
                            name='name_promo'
                            value={_coupon.name_promo || ''}
                            onChange={onChangeCoupon}
                        />
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponCode')} </strong>
                        <Input
                            fieldClassName='promotion_coupon-input'
                            name='promo_code'
                            value={_coupon.promo_code || ''}
                            onChange={onChangeCoupon}
                        />
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.from')} </strong>
                        <DatePicker
                            selected={_coupon.start_date ? new Date(_coupon.start_date) : null}
                            onChange={date => setCoupon({ ..._coupon, start_date: moment(date).format('YYYY-MM-DD') })}
                            customInput={<CustomInput />}
                        />
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.to')} </strong>
                        <DatePicker
                            selected={_coupon.end_date ? new Date(_coupon.end_date) : null}
                            onChange={date => setCoupon({ ..._coupon, end_date: moment(date).format('YYYY-MM-DD') })}
                            customInput={<CustomInput />}
                        />
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponType')} </strong>
                        <Select
                            className='promotion_select'
                            classNameSelected='promotion_selected'
                            options={['Percentage', 'Flat value']}
                            value={_coupon.promo_type || ''}
                            onChange={val => setCoupon({ ..._coupon, promo_type: val })}
                        />
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponValue')} </strong>
                        <Input
                            fieldClassName='promotion_coupon-input'
                            name='promo_value'
                            value={_coupon.promo_value || ''}
                            onChange={onChangeCoupon}
                        />
                    </p>
                </div>
            </div>
        </>
    )
}

PromotionAdd.propTypes = {
    coupon: PropTypes.object,
    onBack: PropTypes.func,
    add: PropTypes.func,
    update: PropTypes.func
}

export default PromotionAdd;
import React from 'react';
import './StoreMenu.scss'
import { StoreInfor, MenuItem } from './components';
import { Card } from 'src/components';
import { useTranslation } from 'react-i18next'

const StoreMenu = (props) => {

    const { t } = useTranslation();
    const setLoadCallBack = (load) => {
        props.callBack(load)
    }

    

    const viewDetailHandle = (item) => {
        props.viewDetailItemCallBack(item)
    }

    return (
        <div className="store-menu">
            
            <Card type="arrow" title={t('information')}>
                <StoreInfor callBack={setLoadCallBack} />
            </Card>
            <Card type="arrow" title={t('menu')}>
                <MenuItem callBack={setLoadCallBack} viewDetailCallBack={viewDetailHandle} />
            </Card>
        </div>
    );
}

export default StoreMenu;
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Back, ProgressBar } from 'src/components'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { useTranslation } from 'react-i18next'

const TransactionOrder = props => {

    const { saleOrder, onBack } = props;
    const { t } = useTranslation();

    const [order, setOrder] = useState();

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.SALE_ORDER + saleOrder)
            .then(res => setOrder(res.data))
    }, [saleOrder])

    const _order = order ? order : {};
    const { restaurant_branch, payment_info, driver, account } = _order;
    const restaurants = (restaurant_branch && restaurant_branch.restaurants) || null;

    return (
        <>
            <Back onBack={onBack} />
            {!order && <ProgressBar style={{ margin: 'auto' }} />}
            {order &&
                <div className="transaction_order">
                    <div className="head">
                        <span>{t('detailOrder')}</span>
                    </div>
                    <div className="body">
                        <div className="left">
                            <div className="form">
                                <span>{t('id')}: </span>
                                <p className='value'>#{_order.id || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.customerName')}: </span>
                                <p className='value'>
                                    {(account && `${account.first_name || ''} ${account.last_name || ''}`) || '---'}
                                </p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.customerPhone')}: </span>
                                <p className='value'>{(account && account.mobile) || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('date')}: </span>
                                <p className='value'>
                                    {(_order.created_datetime && moment(_order.created_datetime).format('MM/DD/YYYY')) || '---'}
                                </p>
                            </div>
                            <div className="form">
                                <span>{t('time')}: </span>
                                <p className='value'  >
                                    {(_order.created_datetime && moment(_order.created_datetime).format('LT')) || '---'}
                                </p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.promoCode')}: </span>
                                <p className='value'>{_order.promo_code || '---'} </p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.note')}: </span>
                                <p className='value'>{_order.note || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.internalCode')}: </span>
                                <p className='value'>{_order.internal_note || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.driverName')}: </span>
                                <p className='value'>
                                    {(driver && `${driver.first_name || ''} ${driver.last_name || ''}`) || '---'}
                                </p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.driverPhone')}: </span>
                                <p className='value'>{(driver && driver.mobile) || "---"}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.commission')}: </span>
                                <p className='value'>{(driver && driver.commission) || "---"}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.paymentMethod')}: </span>
                                {(payment_info && payment_info.id) || ''}
                            </div>
                        </div>
                        <div className="right">
                            <div className="form">
                                <span>{t('orderTable.subTotal')}: </span>
                                <p className='value'>$ {_order.subtotal || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.feeTotal')}: </span>
                                <p className='value'>${_order.fee_total || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.taxTotal')}: </span>
                                <p className='value'>${_order.tax_total || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.chippingFee')}: </span>
                                <p className='value'>${_order.chipping_fee || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.discount')}: </span>
                                <p className='value'>${_order.discount || '---'}</p>
                            </div>
                            <div className="form">
                                <span>{t('orderTable.total')}: </span>
                                <p className='value'>${_order.final_total || '---'}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10%' }}>
                                <img alt='img' src={`${apis.BASE_URL}${(restaurants && restaurants.restaurant_image) || ''}`}
                                    style={{ width: '90%', height: 'auto', marginBottom: '5%' }} />
                                <span style={{ marginBottom: '5%', fontWeight: '500' }}>
                                    <FontAwesomeIcon icon={faStore} />
                                    {(restaurant_branch && restaurants && restaurants.name) || '---'}
                                </span>
                            </div>
                            <div className="form">
                                <span>{t('branch')}: </span>
                                <p className='value'>
                                    {(restaurant_branch && `${restaurant_branch.street_address} ${restaurant_branch.city_province}`) || '---'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

TransactionOrder.propTypes = {
    saleOrder: PropTypes.number,
    onBack: PropTypes.func
}

export default TransactionOrder;
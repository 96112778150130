import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as StarYellow } from '@fortawesome/free-solid-svg-icons'
import { faStar as StarGray } from '@fortawesome/free-regular-svg-icons'

const Rating = props => {

    const { rating, size, color, className } = props;

    return (
        <div className={className}>
            <span>
                <FontAwesomeIcon
                    icon={rating >= 1 ? StarYellow : StarGray}
                    size={size}
                    color={color}
                />
            </span>
            <span>
                <FontAwesomeIcon
                    icon={rating >= 2 ? StarYellow : StarGray}
                    size={size}
                    color={color}
                />
            </span>
            <span>
                <FontAwesomeIcon
                    icon={rating >= 3 ? StarYellow : StarGray}
                    size={size}
                    color={color}
                />
            </span>
            <span>
                <FontAwesomeIcon
                    icon={rating >= 4 ? StarYellow : StarGray}
                    size={size}
                    color={color}
                />
            </span>
            <span>
                <FontAwesomeIcon
                    icon={rating === 5 ? StarYellow : StarGray}
                    size={size}
                    color={color}
                />
            </span>
        </div>
    )
}

Rating.propTypes = {
    rating: PropTypes.number,
    size: PropTypes.oneOf(['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']),
    color: PropTypes.string,
    className: PropTypes.string
}
Rating.defaultProps = {
    rating: 0,
    size: '1x',
    className: ''
}

export default Rating;
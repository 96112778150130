import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import styles from './Back.module.scss'

const Back = props => {

    const { onBack, className, style } = props;
    const { t } = useTranslation();

    return (
        <div onClick={onBack} className={`${styles.back} ${className}`} style={style}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('back')}</span>
        </div>
    )
}

Back.propTypes = {
    onBack: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}
Back.defaultProps = {
    onBack: () => { },
    className: '',
    style: {}
}

export default Back;
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'src/components'
import { useTranslation } from 'react-i18next'

const PromotionCoupon = props => {

    const { t } = useTranslation();
    const { coupon, update, remove } = props;
    const {
        promo_image, promo_code, promo_type,
        promo_value, start_date, end_date,
        name_promo
    } = coupon;

    return (
        <>
            <div className='promotion_coupon'>
                <img src={promo_image} alt='promo_image' />
                <div className='promotion_coupon-infor'>
                    <strong className='promotion_coupon-name'>{name_promo}</strong>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponCode')}: </strong>
                        <span>{promo_code}</span>
                    </p>
                    <p className='promotion_coupon-field'>
                        <span><strong>{t('promotionDetail.from')}: </strong>{start_date}</span>
                        <span style={{ marginLeft: '2rem' }}><strong>to: </strong>{end_date}</span>
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponType')}: </strong>
                        <span>{promo_type}</span>
                    </p>
                    <p className='promotion_coupon-field'>
                        <strong>{t('promotionDetail.couponValue')}: </strong>
                        <span>{promo_value}</span>
                    </p>
                </div>
            </div>
            <div className='promotion_coupon-action'>
                <Button style={{ marginRight: '0.5rem' }} title={t('update')} onClick={update} />
                <Button title={t('delete')} onClick={remove} />
            </div>
        </>
    )
}

PromotionCoupon.propTypes = {
    coupon: PropTypes.object,
    update: PropTypes.func,
    remove: PropTypes.func
}
PromotionCoupon.defautltProps = {
    coupon: {}
}

export default PromotionCoupon;
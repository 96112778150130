import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Back, Button } from 'src/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPhone, faEnvelope, faStore } from '@fortawesome/free-solid-svg-icons'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { useTranslation } from 'react-i18next'

const Assign = props => {

    const { onBack } = props;
    const { t } = useTranslation();
    const [listAccount, setListAccount] = useState([])
    const [account, setAccount] = useState({
        id: "",
        mobile: "",
        email: "",
        first_name: "",
        last_name: "",
        is_active: false,
        is_superuser: false
    })
    const [restaurant, setRestaurant] = useState({
        id: "",
        restaurant_image: "",
        name: "",
        estimated_delivery: "",
        restaurant_rating: "",
        is_active: false,
        type: 1,
        hotline: "",
        commission: 0
    })

    const [listRestaurant, setListRestaurant] = useState([])

    const btnAddClick = () => {
        var payload = {
            "user": account.id,
            "restaurant": restaurant.id,
            "type": 1
        }
        axios.post(apis.BASE_URL + apis.ASSIGN_USER_FOR_RESTAURANT, payload)
            .then(res => {
                alert("Create Successfully!")
                onBack()
            })
            .catch(error => {
                alert("error")
            });
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.USER_ACCOUNTS)
            .then(res => {
                setListAccount(res.data[0].data)
            })
            .catch(error => {
                alert("error")
            });
    }, [])

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.RESTAURANTS)
            .then(res => {
                setListRestaurant(res.data[0].data)
            })
            .catch(error => {
                alert("error")
            });
    }, [])

    const renderAccount = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: '700', color: 'black', fontSize: '1.3rem' }}>{t('newRestaurant.chooseAccount')}: </span>
                <select className="enable" name="payment_method" onChange={changeAccountSelect} style={{ padding: '1%', borderRadius: '15px', color: '#e82d86', outline: 'none', border: 'none', backgroundColor: '#d2d2d259' }}>
                    {
                        listAccount.map((acc, index) => {
                            return <option value={index} key={index} style={{ color: '#e82d86', ontWeight: '400' }}>{acc.first_name + " " + acc.last_name}</option>
                        })

                    }
                </select>
            </div>
        )

    }

    const renderRestaurant = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: '700', color: 'black', fontSize: '1.3rem' }}>{t('restaurant')}: </span>
                <select className="enable" name="payment_method" onChange={changeRestaurantSelect} style={{ padding: '1%', borderRadius: '15px', color: '#e82d86', outline: 'none', border: 'none', backgroundColor: '#d2d2d259' }}>
                    {
                        listRestaurant.map((restaurant, index) => {
                            return <option value={index} key={index} style={{ color: '#e82d86', fontWeight: '400' }}>{restaurant.name}</option>
                        })

                    }
                </select>
            </div>
        )
    }

    const changeAccountSelect = (event) => {
        var acc = listAccount[event.target.value]
        setAccount(acc);
    }

    const changeRestaurantSelect = event => {
        var restaurant = listRestaurant[event.target.value]
        setRestaurant(restaurant)
    }

    const renderButton = () => {
        if (account.id !== "" && restaurant.id !== "") {
            return <Button title={t('assign')} onClick={btnAddClick} />
        }
        else return <Button title={t('assign')} onClick={btnAddClick} disabled />
    }

    return (
        <>
            <Back onBack={onBack} />
            <div className='edit_form'>
                <div className='edit_form-field' style={{ justifyContent: 'space-between', alignItems: 'start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                        {renderAccount()}
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10%' }}>
                            <span style={{ marginBottom: '5%', fontWeight: '500' }}><FontAwesomeIcon icon={faUser} />: {account.first_name + " " + account.last_name}</span>
                            <span style={{ marginBottom: '5%', fontWeight: '500' }}><FontAwesomeIcon icon={faPhone} />: {account.mobile}</span>
                            <span style={{ marginBottom: '5%', fontWeight: '500' }}><FontAwesomeIcon icon={faEnvelope} />: {account.email}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                        {renderRestaurant()}
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10%' }}>
                            <img src={restaurant.restaurant_image||'images/no-image.png'} alt='res_image' style={{ width: '90%', height: 'auto', marginBottom: '5%' }} />
                            <span style={{ marginBottom: '5%', fontWeight: '500' }}><FontAwesomeIcon icon={faStore} /> {restaurant.name}</span>
                        </div>
                    </div>

                </div>
                <div className='edit_form-button'>
                    {renderButton()}
                </div>
            </div>
        </>
    )
}

Assign.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func
}
Assign.defaultProps = {
    onBack: () => { },
    onAdd: () => { }
}

export default Assign;
import React, { useEffect, useState } from 'react';
import Tabs from './component/Tabs'
import { MyStore, Branch, SaleOrder, Payment } from './component/TabPanes';
import { Auth } from 'src/components'
import './StoreConfig.scss'
import { Incoming } from './component';
import apiConfig from 'src/apiConfig'
import axios from 'src/utils/axios'

const StoreConfig = (props) => {

    const [tab, setTab] = useState(0);
    const [order, setOrder] = useState(null);

    

    const changeTab = index => {

        if (index === 4) {
            window.location.href = "/managements"
        }
        else {
            setTab(index);
        }

    }


    const orderClickCallbackHandle = (order) => {
        axios.get(apiConfig.BASE_URL + apiConfig.SALE_ORDER+order.id)
            .then(res => {
                setOrder(res.data)
                setTab(2)
            })
        
    }

    return (
        <div>
            <Auth />
            <Incoming incomingCallback={orderClickCallbackHandle} />
            <div className="store-config">
                <div className="menu-tab">
                    <Tabs changeCallBack={changeTab} />
                </div>
                <div className="content-tab">
                    {tab === 0 && <MyStore />}
                    {tab === 1 && <Branch />}
                    {tab === 2 && <SaleOrder order={order} />}
                    {tab === 3 && <Payment />}
                </div>
            </div>
        </div>

    );
}

export default StoreConfig;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropHeader, DropContent } from 'src/components'
import { useTranslation } from 'react-i18next'

const TransactionMenu = props => {

    const { onChange } = props;
    const { t } = useTranslation();

    const [showDetail, setShowDetail] = useState(false);

    return (
        <div className='transaction_management'>
            <DropHeader
                title={t('deliveryTransactions.title')}
                open={showDetail}
                onClick={() => setShowDetail(!showDetail)}
            />
            <DropContent open={showDetail} className='transaction_detail'>
                <p className='transaction_sub'
                    onClick={() => onChange('transaction-list')}>
                    {t('deliveryTransactions.storeTransactions')}
                </p>
                <p className='transaction_sub'
                    onClick={() => onChange('courier')}>
                    {t('deliveryTransactions.courierTransactions')}
                </p>
            </DropContent>
        </div>
    )
}

TransactionMenu.propTypes = {
    onChange: PropTypes.func
}

export default TransactionMenu;
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { UserList, UserNew, UserEdit, AssignToDriver } from './components'
import { Alert } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'

const AccountUsers = props => {

    const { onBack } = props;

    const [alert, setAlert] = useState({
        type: 'success', open: false, msg: ''
    });
    const [showType, setShowType] = useState('list');
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [index, setIndex] = useState();
    const [reload, setReload] = useState(false);

    
    const setEditUser = (user, index) => {
        setUser(user);
        setIndex(index);
        setShowType('edit')
    }
    const onUpdateUser = (user, index) => {
        axios.patch(apis.BASE_URL + apis.USER_ACCOUNT_UPDATE + user.id, user)
            .then(res => {
                const _users = [...users];
                const _user = res.data[0].data;
                _users.splice(index, 1, { ..._user, id: user.id });
                setUsers(_users);
                setShowType('list');
                setAlert({ type: 'success', open: true, msg: 'Update successfully.' })
            })
            .catch(() => {
                setShowType('list');
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' })
            })
    }
    const setAssignUser = (user, index) => {
        setUser(user);
        setIndex(index);
        setShowType('assign')
    }
    const onAssignUser = (user, index) => {
        axios.post(apis.BASE_URL + apis.DRIVER_ACCOUNT_ASSIGN, user)
            .then(res => {
                setShowType('list');
                setAlert({ type: 'success', open: true, msg: 'Assign successfully.' })
            })
            .catch(() => {
                setShowType('list');
                setAlert({ type: 'error', open: true, msg: 'Assign failed, please try again.' })
            })
    }
    const onDeleteUser = (userID, index) => {
        axios.delete(apis.BASE_URL + apis.USER_ACCOUNT_DELETE + userID)
            .then(res => {
                const _users = [...users];
                _users.splice(index, 1);
                setUsers(_users);
                setAlert({ type: 'success', open: true, msg: 'Delete successfully.' })
            })
            .catch(() => {
                setAlert({ type: 'error', open: true, msg: 'Delete failed, please try again.' })
            })
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.USER_ACCOUNTS)
            .then(res => setUsers(res.data[0].data));
    }, [reload])


    const onAddUserSuccess=()=>{
        setShowType('list')
        setAlert({ type: 'success', open: true, msg: 'Add user successfully.' })
        setReload(!reload)
    }

    return (
        <>
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            {showType === 'list' &&
                <UserList
                    data={users}
                    onNew={() => setShowType('new')}
                    onAssign={setAssignUser}
                    onEdit={setEditUser}
                    onDelete={onDeleteUser}
                    onBack={onBack}
                />
            }
            {showType === 'new' &&
                <UserNew
                    onBack={() => setShowType('list')} onSuccess={onAddUserSuccess}
                />
            }
            {showType === 'edit' &&
                <UserEdit
                    user={user}
                    onBack={() => setShowType('list')}
                    onUpdate={onUpdateUser}
                    index={index}
                />
            }
            {showType === 'assign' &&
                <AssignToDriver
                    user={user}
                    onBack={() => setShowType('list')}
                    onAssign={onAssignUser}
                    index={index}
                />
            }
        </>
    )
}
AccountUsers.propTypes = {
    onBack: PropTypes.func,
}
AccountUsers.defaultProps = {
    onBack: () => { },
}

export default AccountUsers;
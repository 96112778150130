import React, { useState, useEffect } from 'react';
import './DetailBranch.scss'
import { Back, Button, Switch } from 'src/components';
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'
import { useTranslation } from 'react-i18next'


const DetailBranch = (props) => {

    const { t } = useTranslation();
    const [editing, setEditing] = useState(false)
    const [classInput, setClassInput] = useState('disable')
    const [display, setDisplay] = useState('none')
    const [branch, setBranch] = useState({
        id: '',
        street_address: '',
        city_province: '',
        district: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        opening_time: '',
        closure_time: '',
        is_active: '',
        restaurants: ''
    })

    useEffect(() => {
        setBranch(props.branch)
    }, [props.branch])

    const btnAddClick = () => {
        props.addCallBack()
    }

    const switchCallBack = (status) => {
        setEditing(status)
        classInput === 'disable' && setClassInput('enable')
        classInput === 'enable' && setClassInput('disable')
        status === true && setDisplay('block')
        status === false && setDisplay('none')
    }

    const btnUpdateClick = () => {

        const newBranch = {
            id: branch.id,
            street_address: branch.street_address,
            city_province: branch.city_province,
            district: branch.district,
            postal_code: branch.postal_code,
            latitude: branch.latitude,
            longitude: branch.longitude,
            opening_time: branch.opening_time,
            closure_time: branch.closure_time,
            is_active: branch.is_active,
            restaurants: branch.restaurants.id
        }
        axios.patch(apis.BASE_URL + apis.BRANCH_UPDATE + newBranch.id, newBranch)
            .then(res => {
                props.backCallBack()
            })
    }

    const changeCheckbox = (event) => {
        setBranch({ ...branch, [event.target.name]: event.target.checked })
    }

    const changeText = (event) => {
        setBranch({ ...branch, [event.target.name]: event.target.value })
    }

    // const changeLocation = (location) => {
    //     setBranch({ ...branch, latitude: location.lat, longitude: location.lng })
    // }

    return (
        <div className="detail-branch">
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button>
                <button onClick={btnAddClick}>
                    <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '1%' }} />
                    Add
                </button> */}
                <Back onBack={props.backCallBack} />
                <Button title={t('create')} onClick={btnAddClick} />
            </div>
            <div className="branch">
                <div className="head">
                    <span>Detail Branch</span>
                    <div className="right">
                        <span>{t('edit')}</span>
                        <Switch callBack={switchCallBack} />
                    </div>

                </div>
                <div className="body">
                    <div className="form">
                        <span>{t('branchDetail.streetAddress')}: </span>
                        <input className={classInput} type="text" name="street_address" onChange={changeText} value={branch.street_address} disabled={!editing} />
                    </div>
                    <div className="form">
                        <span>{t('newRestaurant.branchOfRestaurant.cityProvince')}: </span>
                        <input className={classInput} type="text" name="city_province" onChange={changeText} value={branch.city_province} disabled={!editing} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.district')}: </span>
                        <input className={classInput} type="text" name="district" onChange={changeText} value={branch.district} disabled={!editing} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.postalCode')}: </span>
                        <input className={classInput} type="text" name="postal_code" onChange={changeText} value={branch.postal_code} disabled={!editing} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.openingTime')}: </span>
                        <input className={classInput} type="number" min={0} max={24} name="opening_time" onChange={changeText} value={branch.opening_time} disabled={!editing} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.closingTime')}: </span>
                        <input className={classInput} type="number" min={0} max={24} name="closure_time" onChange={changeText} value={branch.closure_time} disabled={!editing} />
                    </div>
                    <div className="form-checkbox">
                        <span>{t('active')}: </span>
                        <input className={classInput} type="checkbox" name="is_active" onChange={changeCheckbox} checked={branch.is_active} disabled={!editing} />
                    </div>
                    {/* <div className="map">
                        <GgMap changeLocation={changeLocation} lat={branch.latitude} lng={branch.longitude} />
                    </div> */}
                </div>
                <button style={{ display: display }} onClick={btnUpdateClick}>{t('update')}</button>
            </div>

        </div>
    );
}

export default DetailBranch;
import { baseUrl } from './urlConfig'

const url = baseUrl();

const apiConfig = {
    BASE_URL: url.apiUrl,
    LOGIN: '/account/login',
    // user accounts
    USER_ACCOUNTS: '/account/profile',
    USER_ACCOUNT_NEW: '/account/adminCreate',
    USER_PROFILE_NEW: '/userProfile/',
    USER_ACCOUNT_UPDATE: '/account/update?id=',
    USER_ACCOUNT_DELETE: '/account/delete/',
    CHANGE_PASSWORD: '/account/profile/changepass/',
    // driver accounts
    DRIVER_ACCOUNTS: '/driver/list',
    DRIVER_ACCOUNT: '/driver/',
    DRIVER_COMMISSIONS_UPDATE: '/driver/updateAllCommissions?commission=',
    DRIVER_ACCOUNT_ASSIGN: '/driver/create',
    DRIVER_ACCOUNT_UPDATE: '/driver/update?id=',
    DRIVER_ACCOUNT_DELETE: '/driver/delete?id=',
    FIND_DRIVER_NEAREST: '/trackdelivery/findDriver?secret=7532-bg34-ev32f2-v23423-23678bakpu&sale_order=',
    // operator accounts
    OPERATOR_ACCOUNTS: '/restaurantOperators/listUser',
    OPERATOR_ACCOUNT_DELETE: '/restaurantOperators/delete/',
    // restaurants
    RESTAURANTS: '/restaurants/list',
    RESTAURANTS_BY_CATEGORY: '/menuItem/bycategory?category=',
    RESTAURANT: '/restaurants/my?user=',
    RESTAURANT_UPDATE: '/restaurants/update?id=',
    RESTAURANT_DELETE: '/restaurants/delete?id=',
    RESTAURANT_ADD: '/restaurants/create',
    RESTAURANT_COMMISSIONS_UPDATE: '/restaurants/updateAllCommissions?commission=',
    //store
    STORES: '/restaurants/list?type=2',
    
    // assign user for restaurant
    ASSIGN_USER_FOR_RESTAURANT: '/restaurantOperators/create',
    // branch of the restaurant
    BRANCHS: '/restaurantBranch?restaurant=',
    BRANCH_ADD: '/restaurantBranch/',
    BRANCH_UPDATE: '/restaurantBranch/patch?id=',
    // menu of the restaurant
    MENUS: '/menuItem/list?restaurant=',
    MENU_ADD: '/menuItem/create',
    MENU_UPDATE: '/menuItem/patch?id=',
    MENU_DETAIL: '/menuItem/',
    // promotion coupons
    PROMOTIONS: '/promotion/list',
    PROMOTION_ADD: '/promotion/create',
    PROMOTION_UPDATE: '/promotion/update?id=',
    PROMOTION_DELETE: '/promotion/delete?id=',
    // sale orders
    SALE_ORDERS: '/saleOrder/byRestaurant?restaurant_id=',
    SALE_ORDER_ALL: '/saleOrder/byRestaurant',
    SALE_ORDERS_BY_ID: '/saleOrder/filterById?id=',
    SALE_ORDERS_BY_DATE: '/saleOrder/filterByDate?date=',
    SALE_ORDER: '/saleOrder/',
    SALE_ORDER_ADD: '/saleOrder/create',
    SALE_ORDERS_INCOMING: '/saleOrder/incoming?restaurant_id=',
    SALE_ORDERS_INCOMING_ALL_RESTAURANTS: '/saleOrder/incoming',
    SALE_ORDERS_TRACKING: '/trackdelivery/?sale_order=',
    SALE_ORDER_CONFIRM: '/trackdelivery/tracking',
    SALE_ORDER_CANCEL: '/trackdelivery/tracking',
    SALE_ORDERS_BY_DRIVER: '/saleOrder/byDriver?driver=',
    // tracking
    TRACKING_ADD: '/trackdelivery/tracking',
    // payment method
    PAYMENT_METHODS: '/account/paymentMethod?account=',
    PAYMENT_METHOD_ADD: '/account/paymentMethod',
    PAYMENT_METHOD_UPDATE: '/account/paymentMethod?id=',
    // payments
    ORDER_PAYMENTS: '/orderpayment/list?restaurant_id=',
    ORDER_PAYMENTS_ADD: '/orderpayment/create',
    ORDER_PAYMENTS_UPDATE: '/orderpayment/update?id=',
    //food category
    FOOD_CATEFORIES: '/foodCategory/list',
}

export default apiConfig;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AdminList, AdminAdd } from './components'

const AccountAdmin = props => {

    const { onBack } = props;

    const [showType, setShowType] = useState('list');
    const [admins, setAdmins] = useState([]);

    return (
        <>
            {showType === 'list' &&
                <AdminList
                    data={admins}
                    onBack={onBack}
                    onClick={() => setShowType('add')}
                />
            }
            {showType === 'add' &&
                <AdminAdd
                    onBack={() => setShowType('list')}
                    onAdd={admin => {
                        setAdmins([...admins, admin]);
                        setShowType('list')
                    }}
                />
            }
        </>
    )
}

AccountAdmin.propTypes = {
    onBack: PropTypes.func
}
AccountAdmin.defaultProps = {
    onBack: () => { }
}

export default AccountAdmin;
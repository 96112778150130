import React, { useState } from 'react';
import { ListBranch, DetailBranch, NewBranch } from './components';

function Branch(props) {
    const [load,setLoad] = useState('list-branch')
    const [branchDetail, setBranchDetail] = useState({})

    const viewDetailBranchHandle=(branch)=>{
        setBranchDetail(branch)
        setLoad('detail-branch')
    }

    const backHandle=()=>{
        setLoad('list-branch')
    }

    const addHandle=()=>{
        setLoad('add-branch')
    }

    return (
        <>
            {load==='list-branch' && <ListBranch viewDetailCallBack={viewDetailBranchHandle} addCallBack={addHandle}/>}
            {load==='detail-branch' && <DetailBranch branch={branchDetail} backCallBack={backHandle} addCallBack={addHandle} viewDetailCallBack={viewDetailBranchHandle}/>}
            {load==='add-branch' && <NewBranch backCallBack={backHandle} />}
        </>
    );
}

export default Branch;
import React, { useState, useEffect } from 'react';
import './EditStore.scss'
import Switch from 'src/components/Switch';
import { Back, Button, ProgressBar } from 'src/components';
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { getUser, getRestaurant } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'

const EditStore = (props) => {

    const { t } = useTranslation()
    const [editing, setEditing] = useState(false)
    const [classInput, setClassInput] = useState('disable')
    const [display, setDisplay] = useState('none')
    const [store, setStore] = useState({ id: '', restaurant_image: '', name: '', estimated_delivery: '', restaurant_rating: '', is_active: '', type: '' })
    const [imgReview, setImgReview] = useState([apis.BASE_URL + store.restaurant_image])
    const [haveChangeImg, setHaveChangeImg] = useState(false);
    const [loading, setLoading] = useState(false);

    const { onSuccess } = props
    useEffect(() => {
        axios.get(apis.BASE_URL + apis.RESTAURANT + getUser().id)
            .then(res => {
                setStore(res.data[0].data)
                setImgReview([apis.BASE_URL + res.data[0].data.restaurant_image])
            })
    }, [])

    const btnBackClick = () => {
        props.callBack('store-menu')
    }

    const switchCallBack = (status) => {
        setEditing(status)
        classInput === 'disable' && setClassInput('enable')
        classInput === 'enable' && setClassInput('disable')
        status === true && setDisplay('block')
        status === false && setDisplay('none')
    }

    const changeImg = (event) => {
        setStore({ ...store, "restaurant_image": event.target.files[0] })
        setHaveChangeImg(true)
        setImgReview(URL.createObjectURL(event.target.files[0]))
    }

    const btnUpdateClick = () => {
        setLoading(true)

        var form = new FormData()
        form.append("id", localStorage.getItem("restaurant"))
        form.append("name", store.name)
        form.append("hotline", store.hotline)
        form.append("is_active", store.is_active)
        form.append("restaurant_rating", store.restaurant_rating)
        if (haveChangeImg) {
            form.append("restaurant_image", store.restaurant_image)
        }

        axios.patch(apis.BASE_URL + apis.RESTAURANT_UPDATE + getRestaurant(), form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            onSuccess()
        }).finally(() => setLoading(false))
    }

    const change = (event) => {
        const { name, value } = event.target
        setStore({ ...store, [name]: value })
    }

    const changeActive = (event) => {
        const { name, checked } = event.target
        setStore({ ...store, [name]: checked })
    }

    return (
        <div className="edit-store">
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button> */}
                <Back onBack={btnBackClick} />
            </div>
            <div className="store">
                <div className="head">
                    <span>{t('restaurant')}</span>
                    <div className="right">
                        <span>{t('edit')}</span>
                        <Switch callBack={switchCallBack} />
                    </div>

                </div>
                <div className="body">
                    <div className="picture">
                        <img alt='' src={imgReview} />
                        <input name="restaurant_image" type="file" style={{ display: display }} onChange={changeImg} />
                    </div>
                    <div className="infor">
                        <div className="form">
                            <span>{t('name')}: </span>
                            <input name="name" className={classInput} type="text" onChange={change} value={store.name} disabled={!editing} />
                        </div>
                        <div className="form">
                            <span>{t('phoneNumber')}: </span>
                            <input name="hotline" className={classInput} type="text" onChange={change} value={store.hotline} disabled={!editing} />
                        </div>
                        <div className="form">
                            <span>{t('rating')}: </span>
                            <input name="restaurant_rating" className={classInput} type="number" min={1} max={5} onChange={change} value={store.restaurant_rating} disabled={!editing} />
                        </div>
                        <div className="form">
                            <span>{t('active')}: </span>
                            <input name="is_active" className={classInput} type="checkbox" onChange={changeActive} checked={store.is_active} disabled={!editing} />
                        </div>
                        {/* <button style={{ display: display }} onClick={btnUpdateClick}>Update</button> */}
                        <Button title={t('update')} onClick={btnUpdateClick} style={{ display: display }} />
                        {
                            loading === true
                            &&
                            <div colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ProgressBar />
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default EditStore;
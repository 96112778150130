import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropHeader, DropContent } from 'src/components'
import { useTranslation } from 'react-i18next'

const AccountMenu = props => {

    const { onChange } = props;
    const { t } = useTranslation();

    const [isDrop, setDrop] = useState(false);

    return (
        <div className='account_management'>
            <DropHeader
                title={t('accountManagement')}
                open={isDrop}
                onClick={() => setDrop(!isDrop)}
            />
            <DropContent open={isDrop} className='account_detail'>
                <p onClick={() => onChange('user')} className='account_sub'>{t('userAccount')}</p>
                <p onClick={() => onChange('admin')} className='account_sub'>{t('adminAccount')}</p>
                <p onClick={() => onChange('driver')} className='account_sub'>{t('driverAccount')}</p>
                <p onClick={() => onChange('operator')} className='account_sub'>{t('operatorAdmin')}</p>
            </DropContent>
        </div>
    )
}

AccountMenu.propTypes = {
    onChange: PropTypes.func
}

export default AccountMenu;
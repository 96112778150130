import React, { useState, useEffect } from 'react';
import './ListBranch.scss'
import { Button, ProgressBar } from 'src/components';
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { getRestaurant } from 'src/utils/localStorageService'
import { useTranslation } from 'react-i18next'

const ListBranch = (props) => {

    const { t } = useTranslation();
    const [branchs, setBranchs] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.BRANCHS + getRestaurant())
            .then((res) => {
                setLoading(false)
                setBranchs(res.data[0].data)
            })
    }, [])

    const viewDetailBranch = (branch) => {
        props.viewDetailCallBack(branch)
    }

    const btnAddClick = () => {
        props.addCallBack('new-branch')
    }

    return (
        <div className="list-branch">
            <div className="nav">
                {/* <button onClick={btnAddClick}>
                <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '1%' }} />    
                Add Branch
            </button> */}
                <Button onClick={btnAddClick} title={t('create')} />
            </div>
            <div className="content">
                {
                    loading === true
                    &&
                    <div style={{ marginLeft: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ProgressBar />
                        <span style={{ fontWeight: '400' }}>Loading...</span>
                    </div>
                }
                {
                    branchs.map((branch) => {
                        return (
                            <div className="branch" key={branch.id} onClick={() => viewDetailBranch(branch)}>
                                <div className="address">
                                    <span className="street">{branch.street_address}</span>
                                    <span className="district">{branch.district}, {branch.city_province}</span>
                                </div>
                                <div className="time">
                                    <span>{t('opening')}: {branch.opening_time}h</span>
                                    <span>{t('closing')}: {branch.closure_time}h</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>


        </div>
    );
}

export default ListBranch;
import React, { useState } from 'react'
import {
    AccountSettings,
    AccountManagement,
    ServiceManagement,
    TransactionManagement,
    PromotionManagement,
    PaymentManagement,
    RestaurantManagement,
    ChatSupport,
    StoreManagement,
    OrderIncoming
} from './components'
import { List, Auth } from 'src/components'
import 'src/styles/Managements/Managements.scss'
import { DetailOrder } from '../StoreConfig/component/TabPanes/SaleOrder/components'
import { useTranslation } from 'react-i18next'
import apiConfig from 'src/apiConfig'
import axios from 'src/utils/axios'

const Managements = props => {

    const { t } = useTranslation();

    const dataList = [
        t('accountSetting'),
        t('accountManagement'),
        t('restaurantManagement'),
        t('transactionManagement'),
        t('promotionManagement'),
        t('paymentManagement'),
        t('storeManagement'),
        t('serviceManagement'),
        t('chatSupport')
    ]

    const [item, setItem] = useState(0)
    const [orderIncoming, setOrderIncoming] = useState({})

    const onChangeManagement = index => {
        setItem(index)
    }

    const orderClickCallbackHandle = (order) => {
        axios.get(apiConfig.BASE_URL + apiConfig.SALE_ORDER + order.id)
            .then(res => {
                setOrderIncoming(res.data)
                setItem(9)
            })

    }

    const backHandle = () => {
        setItem(0)
    }

    return (
        <>
            <OrderIncoming incomingCallback={orderClickCallbackHandle} />
            <Auth />
            <h4 className='management_title'>
                {dataList[item]}
            </h4>
            <div className='managements_content'>
                <div className='management_list'>
                    <List dataList={dataList} onClickItem={onChangeManagement} />
                </div>
                <div className='management_detail'>
                    {item === 0 && <AccountSettings />}
                    {item === 1 && <AccountManagement />}
                    {item === 2 && <RestaurantManagement />}
                    {item === 3 && <TransactionManagement />}
                    {item === 4 && <PromotionManagement />}
                    {item === 5 && <PaymentManagement />}
                    {item === 6 && <StoreManagement />}
                    {item === 7 && <ServiceManagement />}
                    {item === 8 && <ChatSupport />}
                    {item === 9 && <DetailOrder allowToEffect={false} order={orderIncoming} onBack={backHandle} driver={null} />}
                </div>
            </div>
        </>
    )
}

export default Managements;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropContent, Back, Button, ProgressBar, Input } from 'src/components'
import { useTranslation } from 'react-i18next'

const Driver = props => {

    const { open, driver, onClick, onUpdate, onDelete, onOrder } = props;
    const { user } = driver;

    const { t } = useTranslation();

    return (
        <div className='account_list'>
            <p className='account_list-item' onClick={onClick}>
                <span className='driver_list-title' onClick={onOrder}>
                    <img style={{ width: '35px' }} src='/images/note.png' alt='order' />
                </span>
                <span className='driver_list-title'>{user.first_name || ''} {user.last_name}</span>
                <span className='driver_list-title'>{user.mobile || ''}</span>
                <span className='driver_list-title'>{t('orderTable.commission')}: {driver.commission}</span>
            </p>
            <DropContent open={open} className='account_list-detail'>
                <div className='account_list--fields'>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('id')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{driver.id || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('orderTable.driverName')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.first_name || ''} {user.last_name}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('phoneNumber')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.mobile || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('email')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{user.email || ''}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span className='account_list-name'>{t('orderTable.commission')}</span>
                            <span className='account_list-name'>:</span>
                        </p>
                        <p className='account_list--key'>{driver.commission}</p>
                    </div>
                    <div className='account_list--field'>
                        <p className='account_list--key'>
                            <span style={{ color: '#e82d86' }} className='account_list-name'>{driver.is_online ? `${t('active')}` : `${t('inactive')}`}</span>
                        </p>
                        <p className='account_list--key'></p>
                    </div>
                </div>
                <div>
                    <iframe
                        title='driver_location'
                        width="100%"
                        height="300"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src={'https://maps.google.com/maps?q=' + driver.latitude + ',' + driver.longitude + '&t=&z=15&ie=UTF8&iwloc=&output=embed'}
                    />
                </div>

                <div className='account_list--button'>
                    <Button title={t('update')} onClick={onUpdate} />
                    <Button title={t('delete')} onClick={onDelete} style={{ marginLeft: '5px' }} />
                </div>
            </DropContent>
        </div>
    )
}

const DriverList = props => {

    const { data, onEdit, onDelete, onOrder, onUpdateCommisson, onBack } = props;
    const { t } = useTranslation();

    const [pos, setPos] = useState(null);
    const [commission, setCommission] = useState('');

    const onChangeIndex = index => {
        if (pos === index) {
            setPos(null)
        } else {
            setPos(index)
        }
    }

    return (
        <>
            <div className='driver_commission'>
                <Back style={{ marginBottom: '0' }} title='Back' onBack={onBack} />
                <div className='driver_commission-input'>
                    <Input
                        placeholder={t('commission')}
                        value={commission}
                        onChange={e => setCommission(e.target.value)}
                    />
                    <Button
                        style={{ marginLeft: '8px' }}
                        title={t('update')}
                        disabled={!commission}
                        onClick={() => {
                            onUpdateCommisson(commission);
                            setCommission('')
                        }}
                    />
                </div>
            </div>
            {data.length === 0 && <ProgressBar style={{ margin: 'auto' }} />}
            {data.map((driver, index) => (
                <Driver
                    key={driver.id}
                    open={pos === index ? true : false}
                    onClick={() => onChangeIndex(index)}
                    driver={driver}
                    onUpdate={() => onEdit(driver, index)}
                    onOrder={() => onOrder(driver)}
                    onDelete={() => {
                        setPos(null);
                        onDelete(driver.id, index)
                    }}
                />
            ))}
        </>
    )
}

DriverList.propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onOrder: PropTypes.func,
    onUpdateCommisson: PropTypes.func,
    onBack: PropTypes.func
}

export default DriverList;
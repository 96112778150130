import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PropTypes from 'prop-types'

const GgMap = (props) => {

  const { changeLocation, lat, lng } = props

  const [location,setLocation] = useState({lat:lat, lng:lng})
  const addMarker = (newLocation, map) => {
    setLocation({ lat: newLocation.lat(), lng: newLocation.lng() })
    changeLocation({ lat: newLocation.lat(), lng: newLocation.lng() })
  };

  return (
    <Map
      google={props.google}
      style={{
        width: "100%",
        height: "100%",
      }}
      initialCenter={location}
      center={location}
      zoom={14}
      onClick={(t, map, c) => addMarker(c.latLng, map)}
    >
      <Marker position={location} />
    </Map>

  );
}
GgMap.propTypes = {
  changeLocation: PropTypes.func,
  lat: PropTypes.number,
  lng: PropTypes.number
}
GgMap.defaultProps = {
  changeLocation: () => { },
  lat: 33.56306346281244,
  lng: -7.609619946653677
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyC3-f5OkhHOB-wMWu5egaJuQXwzR6y4br8'
})(GgMap);
import React, { useState } from 'react';
import './NewBranch.scss'
import axiosInstance from 'src/utils/axiosInstance';
import { Alert, Back, Button } from 'src/components';
import { useTranslation } from 'react-i18next'

const NewBranch = (props) => {

    const { t } = useTranslation();
    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })

    const [branch, setBranch] = useState({
        "street_address": "",
        "city_province": "",
        "district": "",
        "postal_code": "",
        "latitude": 33.56306346281244,
        "longitude": -7.609619946653677,
        "opening_time": "",
        "closure_time": "",
        "is_active": true,
        "restaurants": localStorage.getItem("restaurant")
    })

    const btnBackClick = () => {
        props.backCallBack()
    }

    const btnCreateClick = () => {
        if (branch.city_province !== "" && branch.closure_time !== "" &&
            branch.district !== "" && branch.is_active !== "" &&
            branch.opening_time !== "" && branch.postal_code !== "" && branch.street_address !== "") {
            axiosInstance('/restaurantBranch/', 'POST', branch)
                .then(res => {
                    setAlert({ ...alert, type: 'success', open: true, msg: 'Successfully' })
                    props.backCallBack()
                })
                .catch(err => {
                    setAlert({ ...alert, type: 'error', open: true, msg: 'There are some error while submiting' })
                })
        }
        else {
            alert("Please fill in all fields")
        }
    }

    const change = (event) => {
        const { name, value } = event.target
        setBranch({ ...branch, [name]: value })
    }

    const changeCheckBox = (event) => {
        const { name, checked } = event.target
        setBranch({ ...branch, [name]: checked })
    }

    return (
        <div className="new-branch">
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            <div className="nav">
                {/* <button onClick={btnBackClick}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ paddingRight: '1%' }} />
                    Back
                </button> */}
                <Back onBack={btnBackClick} />
            </div>

            <div className="branch">
                <div className="head">
                    <span>New Branch</span>
                </div>
                <div className="body">
                    <div className="form">
                        <span>{t('branchDetail.streetAddress')}: </span>
                        <input className='enable' type="text" name="street_address" defaultValue={branch.street_address} onChange={change} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.cityProvince')}: </span>
                        <input className='enable' type="text" name="city_province" defaultValue={branch.city_province} onChange={change} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.district')}: </span>
                        <input className='enable' type="text" name="district" defaultValue={branch.district} onChange={change} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.postalCode')}: </span>
                        <input className='enable' type="text" name="postal_code" defaultValue={branch.postal_code} onChange={change} />
                    </div>
                    {/* <div className="form">
                        <span>Latitude: </span>
                        <input className='enable' type="text" name="latitude" defaultValue={branch.latitude} onChange={change}/>
                    </div>
                    <div className="form">
                        <span>Longitude: </span>
                        <input className='enable' type="text" name="longitude" defaultValue={branch.longitude} onChange={change}/>
                    </div> */}
                    <div className="form">
                        <span>{t('branchDetail.openingTime')}: </span>
                        <input className='enable' type="number" name="opening_time" min={0} max={24} defaultValue={branch.opening_time} onChange={change} />
                    </div>
                    <div className="form">
                        <span>{t('branchDetail.closingTime')}: </span>
                        <input className='enable' type="number" name="closure_time" min={0} max={24} defaultValue={branch.closure_time} onChange={change} />
                    </div>
                    <div className="form-checkbox">
                        <span>{t('active')}: </span>
                        <input className='enable' name="is_active" type="checkbox" defaultChecked={branch.is_active} onChange={changeCheckBox} />
                    </div>

                </div>
                {/* <div className='map'>
                    <GgMap changeLocation={changeLocation} lat={branch.latitude} lng={branch.longitude} />
                </div> */}
                <Button onClick={btnCreateClick} title={t('create')} />
            </div>
        </div>
    );
}

export default NewBranch;
import React from 'react'
import PropTypes from 'prop-types'

const User = props => {

    const { user, onClick } = props;

    return (
        <div onClick={onClick} className='user_to_chat'>
            <p className='user_to_chat-avatar'>
                {`${user.first_name[0]}${user.last_name[0]}`.toUpperCase()}
            </p>
            <p className='user_to_chat-name'>
                {user.first_name} {user.last_name}
            </p>
        </div>
    )
}

User.propTypes = {
    user: PropTypes.object,
    onClick: PropTypes.func
}
User.defaultProps = {
    user: {},
    onClick: () => { }
}

export default User;
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropHeader, DropContent } from 'src/components'
import { useTranslation } from 'react-i18next'

const ServiceMenu = props => {

    const { onChange } = props;
    const { t } = useTranslation();

    const [showDetail, setShowDetail] = useState(false);

    return (
        <div className='promotion_management'>
            <DropHeader
                title={t('deliveryServiceManagement.title')}
                open={showDetail}
                onClick={() => setShowDetail(!showDetail)}
            />
            <DropContent open={showDetail} className='transaction_detail'>
                <p className='transaction_sub'
                    onClick={() => onChange('pending-order')}>
                    {t('deliveryServiceManagement.currentOrders')}
                </p>
                <p className='transaction_sub'
                    onClick={() => onChange('invoices')}>
                    {t('deliveryServiceManagement.commissionsAndInvoices')}
                </p>
            </DropContent>
        </div>
    )
}

ServiceMenu.propTypes = {
    onChange: PropTypes.func
}

export default ServiceMenu;
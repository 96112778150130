import React, { useState } from 'react';
import './MyStore.scss'
import { EditStore, StoreMenu, NewItem, EditItem } from './component';
import { Alert } from 'src/components';

const MyStore = (props) => {
    const [load, setLoad] = useState('store-menu')
    const [item,setItem] = useState({})
    const [alert, setAlert] = useState({
        type: 'error',
        open: false,
        msg: ''
    })

    const setLoadCallBackHandle = (load) => {
        setLoad(load)
    }

    const viewDetailItemHandle=(item)=>{
        setLoad('edit-item')
        setItem(item)
    }

    const onEditInforSucceses=()=>{
        setLoad('store-menu')
        setAlert({ ...alert, type: 'success', open: true, msg: 'Change information successfully' })
    }

    const onCreateItemSuccess=()=>{
        setLoad('store-menu')
        setAlert({ ...alert, type: 'success', open: true, msg: 'Create item successfully' })
    }

    const onEditItemSucceses=()=>{
        setLoad('store-menu')
        setAlert({ ...alert, type: 'success', open: true, msg: 'Change item successfully' })
    }

    return (
        <div className="my-store">
            <Alert
                type={alert.type}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                msg={alert.msg}
            />
            {load === 'store-menu' && <StoreMenu callBack={setLoadCallBackHandle} viewDetailItemCallBack={viewDetailItemHandle}/>}
            {load === 'edit-store' && <EditStore callBack={setLoadCallBackHandle} onSuccess={onEditInforSucceses}/>}
            {load === 'new-item' && <NewItem callBack={setLoadCallBackHandle} onSuccess={onCreateItemSuccess}/>}
            {load === 'edit-item' && <EditItem item={item} callBack={setLoadCallBackHandle}  onSuccess={onEditItemSucceses}/>}
        </div>
    );
}

export default MyStore;
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'src/components'
import moment from 'moment'

const MessageBox = props => {

    const mRef = useRef();

    const { userid, message, messageType, onChangeMessage, onChat } = props;

    const sendMessage = event => {
        if (!event.target.value) {
            return;
        }
        if (event.keyCode === 13) {
            onChat();
        }
    }

    useEffect(() => {
        if (mRef.current) {
            mRef.current.scrollTop = mRef.current.scrollHeight
        }
    }, [message])

    return (
        <div className='message'>
            <div ref={mRef} className='message_content'>
                {message.sort((a, b) => a._id - b._id).map((m, inx) => {
                    const dayBeforeOfMessage = inx > 0 ? moment(message[inx - 1]._id).format('MM/DD/YYYY') : moment(message[0]._id).format('MM/DD/YYYY');
                    const dayOfMessage = moment(message[inx]._id).format('MM/DD/YYYY');
                    return (
                        <>
                            {dayOfMessage !== dayBeforeOfMessage &&
                                <p className='message_date'>
                                    <span></span>
                                    <span>{moment(message[inx]._id).format('LL')}</span>
                                </p>
                            }
                            <p className={(m.user && m.user._id === userid) ? 'message_from' : 'message_to'} key={m._id}>
                                <span className='message_text'>{m.text}</span>
                            </p>
                        </>
                    )
                })}
            </div>
            <div className='message_input'>
                <Input
                    disabled={userid ? false : true}
                    className='input'
                    placeholder='Aa'
                    value={messageType.lastMessage.text}
                    onChange={onChangeMessage}
                    onKeyDown={sendMessage}
                />
            </div>
        </div>
    )
}

MessageBox.propTypes = {
    userid: PropTypes.number,
    message: PropTypes.arrayOf(Object),
    messageType: PropTypes.object,
    onChangeMessage: PropTypes.func,
    onChat: PropTypes.func
}

export default MessageBox;
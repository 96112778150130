import React, { useEffect, useState } from 'react';
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import { Alert, Badge, Button, Input, ProgressBar } from 'src/components';
import './ListStores.scss'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'

const ListStore = (props) => {

    const { t } = useTranslation();
    const [restaurants, setRestaurants] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [commission, setCommission] = useState('');
    const [reload, setReload] = useState(false);

    const [alert, setAlert] = useState({
        type: 'success',
        msg: '',
        open: false
    })

    const renderRating = (rating) => {
        var arr = []
        for (var i = 1; i <= rating; i++) {
            arr.push(<img alt='restaurant_image' src="/images/star.png" key={i} style={{ width: '6%', height: 'auto' }} />)
        }
        return arr
    }

    const renderLabel = (restaurant) => {
        if (restaurant.is_active) {
            return <Badge type='active' content={restaurant.hotline} />
        }
        return <Badge type='block' content={restaurant.hotline} />
    }


    const btnAddRestaurantClick = () => {
        props.changePageCallback('add-store')
    }

    const viewDetailClick = (restaurant) => {
        props.viewDetailCallback(restaurant)
    }

    const btnDeleteClick = (restaurant) => {
        confirmAlert({
            message: 'Do you want to delete this restaurant?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete(apis.BASE_URL + apis.RESTAURANT_DELETE + restaurant.id)
                            .then(res => {
                                setRestaurants(restaurants.filter((value, index, arr) => { return value.id !== restaurant.id }))
                                setAlert({ ...alert, type: 'success', msg: 'Delete restaurant successfully!', open: true })
                            })
                            .catch(err => {
                                setAlert({ ...alert, type: 'error', msg: 'Delete restaurant failed!', open: true })
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const changeCategory = (event) => {
        setLoading(true)
        var selectValue = event.target.value
        var url
        if (selectValue === -1) url = apis.BASE_URL + apis.RESTAURANTS
        else url = apis.BASE_URL + apis.RESTAURANTS_BY_CATEGORY + selectValue

        axios.get(url)
            .then(res => {
                setRestaurants(res.data[0].data)
                setLoading(false)
            });
    }

    const renderImg = (restaurant) => {
        if (restaurant.restaurant_image !== null && restaurant.restaurant_image !== undefined) {
            if (restaurant.restaurant_image.startsWith(apis.BASE_URL))
                return <img alt='restaurant_image' src={restaurant.restaurant_image} style={{ width: '3rem', height: '3rem', objectFit: 'cover' }} />
            else return <img alt='restaurant_image' src={apis.BASE_URL + restaurant.restaurant_image} style={{ width: '3rem', height: '3rem', objectFit: 'cover' }} />
        }
        return <img alt='restaurant_image' src={'/images/no-image.png'} style={{ width: '3rem', height: '3rem', objectFit: 'cover' }} />
    }

    const onUpdateCommission = () => {
        axios.patch(apis.BASE_URL + apis.RESTAURANT_COMMISSIONS_UPDATE + commission)
            .then(() => {
                setAlert({ type: 'success', open: true, msg: 'Update successfully.' });
                setCommission('');
                setReload(!reload);
            })
            .catch(() => {
                setAlert({ type: 'error', open: true, msg: 'Update failed, please try again.' });
            })
    }

    useEffect(() => {
        axios.get(apis.BASE_URL + apis.STORES)
            .then(res => {
                setRestaurants(res.data[0].data)
                axios.get(apis.BASE_URL + apis.FOOD_CATEFORIES)
                    .then(res => {
                        setCategories(res.data[0].data)
                        setLoading(false)
                    });
            });
    }, [reload])

    return (
        <>
            <Alert type={alert.type} msg={alert.msg} open={alert.open} onClose={() => { setAlert({ type: 'success', msg: '', open: false }) }} />
            <div className="list-restaurant" >
                <div className="nav">
                    <Button title={t('create')} onClick={btnAddRestaurantClick} />
                    <select onChange={changeCategory} >
                        <option value="-1">--View all--</option>
                        {
                            categories.map((c, index) => {
                                return (
                                    <option value={c.id} key={index}> {c.category_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'flex-end' }}>
                    <Input
                        placeholder={t('commission')}
                        value={commission}
                        onChange={e => setCommission(e.target.value)}
                    />
                    <Button
                        style={{ marginLeft: '8px' }}
                        title={t('update')}
                        disabled={!commission}
                        onClick={onUpdateCommission}
                    />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('image')}</th>
                            <th>{t('name')}</th>
                            <th>{t('rating')}</th>
                            <th>{t('orderTable.commission')}</th>
                            <th>{t('newRestaurant.hotline')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading === true
                            &&
                            <tr>
                                <td colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <ProgressBar />
                                    <p style={{ fontWeight: '400' }}>Loading...</p>
                                </td>
                            </tr>
                        }
                        {
                            loading === false
                            &&
                            restaurants.map((restaurant, index) => {
                                return (
                                    <tr key={index}>
                                        <th>#{restaurant.id}</th>
                                        <td>{renderImg(restaurant)}</td>
                                        <th>{restaurant.name}</th>
                                        <td>{renderRating(restaurant.restaurant_rating)}</td>
                                        <td>{restaurant.commission}</td>
                                        <td className="label">{renderLabel(restaurant)}</td>
                                        <td><a className="view-detail" onClick={() => viewDetailClick(restaurant)}>{t('view')}</a></td>
                                        <td style={{ paddingLeft: '1%', paddingRight: '1%' }}><FontAwesomeIcon icon={faTrash} onClick={() => btnDeleteClick(restaurant)} color='#e82d86' /></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {restaurants.length === 0 && <div style={{ color: '#e82d86', marginTop: '15%', marginLeft: '30%' }}>No data.</div>}
            </div>
        </>
    );
}

export default ListStore;
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { MainLayout, MiniLayout } from './layout'
import {
  NotFound,
  StoreConfig,
  Login,
  Managements,
  Policy
} from './views'

// const requireAuthen = (component) => {
//   if (!localStorage.getItem("token")) {
//     return Login
//   }
//   return component
// }

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Login}
        exact
        layout={MiniLayout}
        path="/"
      />
      <RouteWithLayout
        component={Login}
        exact
        layout={MiniLayout}
        path="/login"
      />
      <RouteWithLayout
        component={StoreConfig}
        exact
        layout={MainLayout}
        path="/store-config"
      />
      <RouteWithLayout
        component={Managements}
        exact
        layout={MainLayout}
        path="/managements"
      />
      <Route
        exact
        path="/privacy.html"
        component={Policy}
      />
      <RouteWithLayout
        component={NotFound}
        exact
        layout={MiniLayout}
        path='*'
      />
    </Switch>
  );
};

export default Routes;

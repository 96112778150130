import React, { useEffect, useState } from 'react'
import { UserList, UserSelected, MessageBox } from './components'
import { fireStore } from 'src/utils/firebase'
import { getUser } from 'src/utils/localStorageService'
import apis from 'src/apiConfig'
import axios from 'src/utils/axios'

const ChatSupport = props => {

    const userAdmin = getUser();

    const [users, setUsers] = useState([]);
    const [showUsers, setShowUsers] = useState(false);
    const [user, setUser] = useState();
    const [message, setMessage] = useState([]);
    const [messageType, setMessageType] = useState({
        lastMessage: {
            _id: null,
            createdAt: null,
            text: '',
            user: {
                _id: userAdmin.id,
                name: `${userAdmin.first_name} ${userAdmin.last_name}`
            }
        }
    });

    const onChangeUser = user => {
        setUser(user);
        setShowUsers(false);
    }

    // const fetchUserIDFirebase = () => {
    //     fireStore.collection('SupportChats')
    //         .onSnapshot(users => {
    //             const _users = [];
    //             users.forEach(user => _users.push(user.id));
    //             setUserIds(_users);
    //         });
    // }
    const fetchUsers = () => {
        axios.get(apis.BASE_URL + apis.USER_ACCOUNTS)
            .then(res => setUsers(res.data[0].data.filter(user => user.id !== 1)))
    }
    const getMessages = userid => {
        fireStore.collection('SupportChats')
            .doc(userid).collection('messages')
            .onSnapshot(mess => {
                const _messages = [];
                mess.forEach(mes => _messages.push(mes.data()));
                setMessage(_messages);
            })
    }
    const onChangeMessage = event => {
        setMessageType({
            lastMessage: {
                ...messageType.lastMessage,
                text: event.target.value
            }
        })
    }
    const updateLastMessage = (message, userid) => {
        fireStore.collection('SupportChats').doc(`userid_${userid}`)
            .set(message)
            .catch(err => console.log(err))
    }
    const addMessage = (message, userid) => {
        fireStore.collection('SupportChats').doc(`userid_${userid}`)
            .collection('messages')
            .add(message.lastMessage)
            .then(() => updateLastMessage(message, userid))
            .catch(err => console.log(err))
    }
    const onChat = () => {
        const messageChat = {
            lastMessage: {
                ...messageType.lastMessage,
                _id: new Date().getTime(),
                createdAt: fireStore.Timestamp.fromDate(new Date())
            }
        }
        setMessageType({
            lastMessage: {
                ...messageType.lastMessage,
                _id: null,
                createdAt: null,
                text: ''
            }
        })
        addMessage(messageChat, user.id);
    }

    useEffect(() => {
        if (user) {
            getMessages(`userid_${user.id}`);
        }
    }, [user]);

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <div className='chat'>
            <div className='chatBox'>
                <div className='chatBox_header'>
                    <UserSelected user={user} onClick={() => setShowUsers(!showUsers)} />
                    <UserList open={showUsers} users={users} onChangeUser={onChangeUser} />
                </div>
                <div className='chatBox_content'>
                    <MessageBox
                        userid={user && user.id}
                        message={message}
                        messageType={messageType}
                        onChangeMessage={onChangeMessage}
                        onChat={onChat}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChatSupport;

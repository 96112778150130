import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styles from './DropHeader.module.scss'

const styleIcon = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#e82d86',
    color: '#ffffff'
}

const DropHeader = props => {

    const { drop_header } = styles;

    const { title, open, icon, onClick, className, style } = props;

    return (
        <p className={`${drop_header} ${className}`}
            style={style}
            onClick={onClick}>
            {title}
            <>
                {icon && (
                    <span style={styleIcon}>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            style={{ transition: 'all 300ms ease-out', transform: open ? 'rotate(-90deg)' : 'rotate(0deg)' }}
                        />
                    </span>
                )}
            </>
        </p>
    )
}

DropHeader.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    icon: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}
DropHeader.defaultProps = {
    open: false,
    icon: true,
    className: '',
    style: {}
}

export default DropHeader;
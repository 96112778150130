import React from 'react';
import './Pagination.scss'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Pagination = (props) => {

    const { currentPage, preClick, nextClick } = props;
    const { t } = useTranslation();

    return (
        <div className="pagination" style={!currentPage ? { display: 'none' } : null}>
            <span className="page" onClick={preClick}
                style={currentPage === 1 ? { pointerEvents: 'none', opacity: 0.5 } : null}>
                <FontAwesomeIcon icon={faArrowLeft} />
                {t('previous')}
            </span>
            <span className="page" onClick={nextClick}>
                {t('next')}
                <FontAwesomeIcon icon={faArrowRight} />
            </span>
        </div>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    preClick: PropTypes.func,
    nextClick: PropTypes.func
}
Pagination.defaultProps = {
    preClick: () => { },
    nextClick: () => { }
}

export default Pagination;
import React, { useState } from 'react';
import { ListRestaurants, AddRestaurant, EditRestaurant } from './components'

const RestaurantManagement = (props) => {
    const [load, setLoad] = useState('list-restaurants')
    const [detailRestaurant,setDetailRestaurant] = useState({})

    const loadPage = (page) =>{
        setLoad(page)
    }

    const viewDetailCallbackHandle=(restaurant)=>{
        setLoad('edit-restaurant');
        setDetailRestaurant(restaurant)
    }

    return (
        <>
            {load === 'list-restaurants' && <ListRestaurants changePageCallback = {loadPage} viewDetailCallback={viewDetailCallbackHandle}/>}
            {load === 'add-restaurant' && <AddRestaurant onBack={()=>{setLoad('list-restaurants')}} />}
            {load === 'edit-restaurant' && <EditRestaurant restaurant={detailRestaurant} onBack={()=>{setLoad('list-restaurants')}} />}
        </>
    )
}

export default RestaurantManagement;
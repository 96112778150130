import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button, ProgressBar } from 'src/components'
import axios from 'src/utils/axios'
import apis from 'src/apiConfig'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const customStyles = {
    content: {
        textAlign: 'center',
        width: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// const mockData = [
//     {
//         "id": 9,
//         "is_online": true,
//         "latitude": "21.032877",
//         "longitude": "105.812046",
//         "user": {
//             "id": 3,
//             "mobile": "+8412345678",
//             "email": "webili@gmail.com.vn",
//             "first_name": "web",
//             "last_name": "vn"
//         }
//     },
//     {
//         "id": 8,
//         "is_online": true,
//         "latitude": "21.026027",
//         "longitude": "105.788443",
//         "user": {
//             "id": 2,
//             "mobile": "+2426618443111",
//             "email": "linxhqweb3@linxhq.com.vn",
//             "first_name": "updated fn",
//             "last_name": "updated last_name"
//         }
//     }
// ]


const ModalDrivers = (props) => {

    const { t } = useTranslation();
    const { order, show, onClose, onSuccess } = props;
    const [loading, setLoading] = useState(true);
    const [drivers, setDrivers] = useState([]);
    const [msg, setMsg] = useState("Finding driver...");

    const noDriver = t('noDriver');

    useEffect(() => {
        setTimeout(
            () => {
                axios.get(apis.BASE_URL + apis.FIND_DRIVER_NEAREST + order.id + '&auto_assign=0')
                    .then(res => {
                        if (res.data[0].data !== null) {
                            setDrivers(res.data[0].data); setMsg('success');
                        } else setMsg(noDriver)
                        setLoading(false)
                    })
            },
            1000
        )
    }, [order, noDriver])

    function afterOpenModal() {
    }

    function closeModal() {
        setMsg(t('findingDriver'))
        setLoading(true)
        onClose()
    }

    const btnRefreshClick = () => {
        setMsg(t('findingDriver'))
        setLoading(true)
        setTimeout(
            () => {
                axios.get(apis.BASE_URL + apis.FIND_DRIVER_NEAREST + order.id + '&auto_assign=0')
                    .then(res => {
                        if (res.data[0].data !== null) {
                            setDrivers(res.data[0].data); setMsg('success');
                        } else setMsg("No driver.")
                        setLoading(false)
                    })
            },
            1000
        )

    }

    const btnMatchClick = (id) => {
        axios.post(apis.BASE_URL + apis.TRACKING_ADD,
            {
                "sale_order": order.id,
                "status": 1,
                "time": new Date().toISOString(),
                "driver": id,
                "longitude": null,
                "latitude": null
            }
        )
            .then(res => {
                onSuccess()
            })
    }

    if (loading) {
        return (
            <div>
                <Modal
                    isOpen={show}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ProgressBar />
                        <span style={{ fontWeight: '400' }}>{msg}</span>
                    </div>
                </Modal>
            </div>
        );
    }
    else return (
        <div>
            <Modal
                isOpen={show}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                {
                    msg !== 'success'
                    &&
                    <div colSpan={11} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontWeight: '400', color: '#e82d86' }}>{msg}</span>
                    </div>
                }
                <table>
                    <tbody>
                        {
                            drivers.map((dr, index) => {
                                return (
                                    <tr key={index}>
                                        <th>{index + 1}</th>
                                        <th>{dr.user.first_name + " " + dr.user.last_name}</th>
                                        <td>{dr.user.mobile}</td>
                                        <td>{dr.user.email}</td>
                                        <td>
                                            <Button title='Match' onClick={() => btnMatchClick(dr.id)} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <Button title='Close' color={'#d2d2d259'} onClick={closeModal} />
                {
                    msg === 'No driver.' && <Button title='Refresh' onClick={btnRefreshClick} />
                }
            </Modal>
        </div>
    );
}

ModalDrivers.propTypes = {
    order: PropTypes.object,
    drivers: PropTypes.array,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
}
ModalDrivers.defaultProps = {
    order: {},
    drivers: [],
    show: false,
    onClose: () => { },
    onSuccess: () => { },
}
export default ModalDrivers;